<template>
  <div class="account-container">
    <!-- <div> {{ $t('account.accountBinding') }}</div> -->
    <div
      class="flex-row-center f-between mb16"
      style="height: 48px;"
    >
      <div class="title-header-text">
        {{ $t('account.accountList') }}
      </div>
      <div
        v-if="quota.socialCount"
        class="flex-row-center"
      >
        <span class="mr5">{{ $t('account.accountQuota') }}：</span>
        <Slider
          :value="quota.useSocialCount || 0"
          :maxValue="quota.socialCount || 10"
        />
        <div class="ml5">
          {{ quota.useSocialCount || 0}}/{{ quota.socialCount || 100}}
        </div>
      </div>
    </div>
    <div class="account-content">
      <div class="flex-row-center f-between mb10">
        <el-button
          class="gradient-button"
          icon="el-icon-plus"
          @click="bindAccount"
        >{{ $t('account.bindAccount') }}</el-button>
        <div class="flex-row-center">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('refresh')"
            placement="top"
          >
            <i
              class="iconfont icon-a-danyuanpeizhi6"
              @click="init"
            ></i>
          </el-tooltip>
          <div
            class="ml8"
            style="width: 400px;margin-right: 5px;"
          >
            <el-input
              class="account-input"
              v-model="queryParams.searchValue"
              :placeholder="$t('account.tipAccount')"
              clearable
              @keyup.enter.native="handleQuery"
            >
              <i
                slot="prefix"
                class="el-input__icon iconfont icon-sousuotubiao"
                @click="handleQuery"
              ></i>
            </el-input>
          </div>
        </div>
      </div>
      <el-table
        v-loading="loading"
        header-row-class-name="custom_table_header"
        height="calc(100vh - 320px)"
        :data="accountList"
        :row-style="setRowStyle"
        @filter-change="filterChange"
        @current-change="handleCurrentChange"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
      >
        <!-- :span-method="mergeCells" -->
        <el-empty
          :image-size="180"
          slot="empty"
        ></el-empty>
        <!-- <el-table-column label="ID" prop="profileId" >
        </el-table-column> -->
        <el-table-column
          column-key="topHeader"
          prop="topHeader"
          :label="$t('account.user')"
          :filters="profileFilters"
          :filter-multiple="false"
          width="280"
        >
          <template slot-scope="scope">
            <div class="single-line-ellipsis">
              {{ scope.row.topHeader }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          column-key="platform"
          prop="platform"
          :label="$t('account.bindingSource')"
          :filters="socialPlatformFilters"
          filter-placement="bottom"
          width="150"
        >
          <!-- :filter-method="filterPlatform" -->
          <template slot-scope="scope">
            <div
              class="flex-row-center single-line-ellipsis"
              style="padding: 5px"
            >
              <i
                class="iconfont mr5 font20"
                :class="['icon-' + scope.row.platform + '-normal', scope.row.platform]"
              ></i>
              <dict-tag
                :options="dict.type.social_platform"
                :value="scope.row.platform"
              />
              <!-- {{ scope.row.platform }} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('account.bindHomePage')"
          prop="username"
        >
          <template slot-scope="scope">
            <div
              class="flex-row-center"
              :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'"
            >
              <el-avatar
                :src="scope.row.userImage"
                size="small"
                style="margin-right: 10px;flex-shrink: 0;"
              ></el-avatar>
              <div
                class="f-grow single-line-ellipsis"
                style="text-align: left;"
              >
                {{ scope.row.platform == 'facebook'? scope.row.pageName :scope.row.username }}
              </div>
            </div>
            <div
              v-show="scope.row.isHoverCell"
              class="flex-row-center operate-hover"
            >
              <el-tooltip :content="$t('view')">
                <div
                  class="flex-row-center list-button"
                  @click="handleView(scope.row)"
                >
                  <i class="iconfont icon-a-xialajiantoubeifen3"></i>
                  {{ $t('view') }}
                </div>
              </el-tooltip>
              <el-tooltip :content="$t('edit')">
                <div
                  class="flex-row-center list-button"
                  @click="handleEdit(scope.row)"
                >
                  <i class="iconfont icon-edit"></i>
                  {{ $t('edit') }}
                </div>
              </el-tooltip>
              <el-tooltip :content="$t('account.unbundle')">
                <div
                  v-loading="deleteLoading && scope.row.id == id"
                  class="flex-row-center list-button"
                  @click="handleUnbind(scope.row)"
                >
                  <i class="iconfont icon-a-xialajiantoubeifen22"></i>
                  {{ $t('account.unbundle') }}
                </div>
              </el-tooltip>
              <el-tooltip :content="$t('account.tipSetDefault')">
                <div
                  class="flex-row-center list-button"
                  @click="openSettings(scope.row)"
                >
                  <i class="iconfont icon-a-xialajiantoubeifen23"></i>
                  {{ $t('account.set') }}
                </div>
              </el-tooltip>
              <el-tooltip :content="$t('remove')">
                <div
                  class="flex-row-center list-button"
                  @click="handleDelete(scope.row)"
                >
                  <i class="iconfont icon-delete"></i>
                  {{ $t('remove') }}
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('account.nickname')"
          prop="displayName"
        >
          <template slot-scope="scope">
            <div :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'">
              {{ scope.row.displayName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('account.countryAndLanguage')"
          prop="languageId"
          width="200"
        >
          <template slot-scope="scope">
            <div :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'">
              {{ formatLanguage(scope.row.languageId) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('account.bindTime')"
          prop="createTime"
          width="200"
        >
          <template slot-scope="scope">
            <span :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'">{{ parseTime(scope.row.createTime , '{y}-{m}-{d} {h}:{i}') }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column :label="$t('account.bindingState')" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">{{ $t('status.initiate') }}</span>
            <span v-else>{{ $t('status.disable') }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column :label="$t('operate')" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-tooltip :content="$t('account.unbundle')">
              <el-button v-loading="deleteLoading && scope.row.id == id" icon="iconfont icon-delete" circle type="text" @click="handleUnbind(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip :content="$t('account.tipSetDefault')">
              <el-button icon="el-icon-setting" circle type="text" @click="openSettings(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>

    <el-dialog
      class="publicDialog"
      width="700px"
      :visible.sync="settingsVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('account.setDefault') }}</div>
          <i
            class="el-icon-close"
            @click="settingsVisible = false"
          ></i>
        </div>
      </div>
      <el-form
        label-width="100px"
        ref="settingsForm"
        label-position="top"
        :model="settingsForm"
        :rules="settingsRules"
      >
        <el-form-item
          :label="$t('account.language')"
          prop="languageId"
        >
          <el-select
            v-model="settingsForm.languageId"
            @change="updateCountries"
            clearable
            filterable
            class="w-100"
          >
            <el-option
              v-for="language in languageOptions "
              :key="language.id"
              :label="language.name"
              :value="language.id"
              class="ellipsis-option"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('account.country')"
          prop="country"
        >
          <el-select
            v-model="settingsForm.country"
            disabled
            class="w-100"
          >
            <el-option
              :label="settingsForm.country"
              :value="settingsForm.country"
              class="ellipsis-option"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="timeZoneId"
        >
          <el-select
            v-model="settingsForm.timeZoneId"
            class="w-100"
          >
            <el-option
              v-for="time in timeOptions"
              :key="time.value"
              :label="time.label"
              :value="time.value"
              class="ellipsis-option"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="cancelBtn btnWidth120"
          @click="settingsVisible = false"
        >{{$t('cancel')}}</el-button>
        <el-button
          class="submitBtn btnWidth120"
          @click="saveSettings"
        >{{$t('save')}}</el-button>
      </span>
    </el-dialog>
    <!-- 选择账号协同id的弹窗 -->
    <ProfileDialog
      v-loading="pageLoading"
      ref="profile"
      :visible.sync="profileVisible"
      :profileTypes="profileTypes"
      :isExceed="isExceedQuota(quota.useSocialCount, quota.socialCount)"
      @action="openTab"
      @createProfileAction="createProfileAction"
    />
    <!-- 查看、编辑抽屉 -->
    <el-drawer
      class="publicDrawer"
      :size="700"
      :title="$t('account.accountDetails')"
      :visible.sync="profileDrawer"
      :with-header="true"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('account.accountDetails') }}</div>
          <!-- <i
            class="el-icon-close"
            @click="profileDrawer = false"
          ></i> -->
        </div>
      </div>
      <Detail
        ref="profileDeatil"
        :profileTypes="profileTypes"
        @cancel="profileDrawer = false"
        @update="updateDetail"
        style="padding: 20px;"
      ></Detail>
    </el-drawer>
    <repeat-dialog
      :visible.sync="repeatDialogVisible"
      :repeatList="repeatList"
      @unbindSueecss="queryRepeat"
    />
  </div>
</template>

<script>
import {
  addProfile,
  clearProfile,
  delProfile,
  generateJwt,
  listProfile,
} from "../../api/account/profile";
import {
  delAccount,
  editAccount,
  listAccount,
  queryRepeat,
} from "../../api/account/tiktok";
import { listLanguage } from "../../api/ai/language";
import { getQuota } from "../../api/system/merchant";
import ProfileDialog from "./profileDialog.vue";
import Slider from "./slider.vue";
import Detail from "./detail.vue";
import RepeatDialog from "./components/repeatDialog.vue";

export default {
  // name: "Account",
  dicts: ["social_platform"],
  components: {
    ProfileDialog,
    Slider,
    Detail,
    RepeatDialog,
  },
  data() {
    return {
      pageLoading: false,
      // 遮罩层
      loading: true,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 账号数据
      accountList: [],
      // 弹出层标题
      title: "",
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
      },
      // 控制设置弹出框的显示
      settingsVisible: false,
      // 设置表单数据
      settingsForm: {
        id: "",
        country: "",
        languageId: "",
        timeZoneId: "",
      },
      languageOptions: [],
      timeOptions: [],
      // 设置表单校验
      settingsRules: {
        country: [
          {
            required: true,
            message: this.$t("account.tipCountry"),
            trigger: ["blur", "change"],
          },
        ],
        languageId: [
          {
            required: true,
            message: this.$t("account.tipLanguage"),
            trigger: ["blur", "change"],
          },
        ],
        timeZoneId: [
          {
            required: true,
            message: this.$t("account.tipTimezone"),
            trigger: ["blur", "change"],
          },
        ],
      },
      quota: {}, //配额
      profileVisible: false,
      newTab: null,
      profile: [],
      profileFilters: [],
      profileTypes: [
        // 'facebook',
        // 'gmb',
        "instagram",
        // 'linkedin',
        // 'pinterest',
        // 'reddit',
        // 'telegram',
        "tiktok",
        // 'twitter',
        "youtube",
      ],
      deleteLoading: false,
      id: undefined,
      profileDrawer: false,
      repeatList: [], // 重复的账号列表
      repeatDialogVisible: false,
    };
  },
  computed: {
    socialPlatformFilters() {
      this.profileTypes = this.dict.type.social_platform.map(
        (type) => type.value
      );
      return this.dict.type.social_platform.map((type) => ({
        text: type.label,
        value: type.value,
      }));
    },
  },
  created() {
    this.init();
  },
  mounted() {
    window.addEventListener("message", this.handleMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    // 设置固定行高
    setRowStyle() {
      return { height: "60px" };
    },
    cellMouseLeave(row, column, cell, event) {
      row.isHoverCell = false;
    },
    cellMouseEnter(row, column, cell, event) {
      row.isHoverCell = true;
    },
    handleCurrentChange(val) {
      this.id = val.id;
    },
    filterChange(filter) {
      if (filter["platform"]) {
        // 修改传给后端接口的参数，并重新调用接口
        this.queryParams.platform = filter.platform.join(",");
        this.handleQuery();
      } else if (filter["topHeader"]) {
        this.queryParams.topHeader = filter.topHeader.join(",");
        this.handleQuery();
      }
    },
    filterPlatform(value, row) {
      this.queryParams.platform = value;
      this.handleQuery();
      // return row.platform === value;
      // return true;
    },
    mergeCells({ row, column, rowIndex, columnIndex }) {
      // 只在id列（columnIndex === 0）合并单元格
      if (columnIndex === 0) {
        const prevRow = this.accountList[rowIndex - 1];
        if (prevRow && prevRow.profileId === row.profileId) {
          return [0, 0]; // 隐藏重复的行
        }
        let rowspan = 1;
        for (let i = rowIndex + 1; i < this.accountList.length; i++) {
          if (this.accountList[i].profileId === row.profileId) {
            rowspan++;
          } else {
            break;
          }
        }
        return [rowspan, 1]; // 合并行数
      }
    },
    bindAccount() {
      // if (
      //   this.isExceedQuota(this.quota.useSocialCount, this.quota.socialCount)
      // ) {
      //   this.$modal.msgError(this.$t("material.exceedQuota"));
      //   return;
      // }
      // if(this.profile && this.profile.length > 0){
      this.profileVisible = true;
      this.$nextTick().then((rs) => {
        this.$refs.profile.init();
      });
      // } else {
      //   this.openTab()
      // }
    },
    // 浏览器要求 window.open() 必须在用户交互事件（如点击、触摸等）中直接触发，而不能在异步回调或延迟中触发。
    // 所以采取方案：预先打开空白页面，然后重定向
    openTab({ id, topHeader, form }) {
      const width = window.screen.width * 0.8;
      const height = window.screen.height * 0.8;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;
      const features = `width=${width},height=${height},left=${left},top=${top},location=no,menubar=no,status=no,toolbar=no,resizable=yes,scrollbars=yes`;
      this.newTab = window.open("about:blank", "_blank", features); // 打开新的空白窗口
      // 检查是否成功打开了新标签页
      if (this.newTab) {
        this.profileVisible = false;
        this.loadAnimation();
        if (id) {
          this.generateJwt(id);
        } else if (topHeader) {
          this.createProfile(topHeader);
        } else if (form) {
          this.createProfileNew(form);
        }
      } else {
        console.error("新标签页被浏览器拦截");
      }
    },
    loadAnimation() {
      if (this.newTab) {
        // 在新窗口中写入加载动画的 HTML 和 CSS
        const loadingHTML = `
          <html>
            <head>
              <style>
                body {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                  margin: 0;
                  background-color: #f0f0f0;
                }
                .loader {
                  border: 5px solid #f3f3f3; /* Light grey */
                  border-top: 5px solid #3498db; /* Blue */
                  border-radius: 50%;
                  width: 40px;
                  height: 40px;
                  animation: spin 2s linear infinite;
                }
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              </style>
            </head>
            <body>
              <div class="loader"></div>
            </body>
          </html>
        `;
        // 将加载动画写入新窗口
        this.newTab.document.open();
        this.newTab.document.write(loadingHTML);
        this.newTab.document.close();
      }
    },
    createProfile(topHeader) {
      addProfile({ topHeader: topHeader })
        .then((response) => {
          let profileId = response.data;
          this.generateJwt(profileId);
        })
        .catch(() => {
          this.closeTab();
        });
    },
    createProfileAction({ form }) {
      this.pageLoading = true;
      addProfile(form)
        .then((response) => {
          this.pageLoading = false;
          this.$modal.msgSuccess(this.$t("account.createProfile"));
          this.$refs.profile.clearForm();
          this.$refs.profile.getProfileList();
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
    createProfileNew(form) {
      addProfile(form)
        .then((response) => {
          let profileId = response.data;
          this.generateJwt(profileId);
        })
        .catch(() => {
          this.closeTab();
        });
    },
    generateJwt(id) {
      generateJwt(id)
        .then((response) => {
          this.openNewTabAndRedirect(response.data);
        })
        .catch(() => {
          this.closeTab();
        });
    },
    closeTab() {
      if (this.newTab) this.newTab.close();
    },
    openNewTabAndRedirect(jwt) {
      console.log("jwt", jwt);
      if (this.newTab) {
        const redirectURI = `${window.location.origin}/callback`;
        this.newTab.location.href = `${jwt}&redirect=${encodeURIComponent(
          redirectURI
        )}`;
      }
      // var newTab =
      // window.open(`https://profile.ayrshare.com?domain=id-38848&jwt=${jwt}&redirect=null`, '_blank', 'width=800,height=600');
      // newTab.focus();
    },
    init() {
      this.clearProfile();
      this.getQuota();
      this.getList();
      this.getLanguage();
      this.getProfileList();
      this.queryRepeat();
    },
    clearProfile() {
      clearProfile();
    },
    queryRepeat() {
      queryRepeat().then((response) => {
        this.repeatList = response.data;
        if (this.repeatList.length > 0) {
          this.repeatDialogVisible = true;
        } else {
          this.repeatDialogVisible = false;
        }
      });
    },
    getProfileList() {
      listProfile().then((response) => {
        this.profile = response.rows;
        this.profileFilters = response.rows
          .filter(
            (type) =>
              type.activeSocialAccounts && type.activeSocialAccounts !== "[]"
          )
          .map((type) => ({
            text: type.topHeader,
            value: type.topHeader,
          }));
      });
    },
    //获取配额
    getQuota() {
      getQuota().then((response) => {
        this.quota = response.data;
      });
    },
    handleMessage(event) {
      // 允许的来源
      // const allowedOrigins = ['http://localhost:81', 'http://192.168.0.106:81', 'https://pub.grds.cn', 'https://pub.infonity.ai'];
      const allowedOrigins = [
        "http://localhost:81",
        "http://192.168.0.106:81",
        window.location.origin,
      ];
      if (allowedOrigins.includes(event.origin)) {
        // if(event.data == '绑定成功'){
        //   this.$modal.msgSuccess(event.data)
        this.clearProfile();
        this.getList(); // 刷新页面
        this.getQuota(); // 刷新配额
        this.queryRepeat(); // 刷新重复账号
        // }else{
        //   this.$modal.msgError(event.data)
        // }
      }
    },
    /** 账号列表 */
    getList() {
      this.loading = true;
      listAccount(this.queryParams)
        .then((response) => {
          this.loading = false;
          const newList = response.rows.map((item) => {
            return { ...item, isHoverCell: false };
          });
          this.accountList = newList;
          this.total = response.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getLanguage() {
      listLanguage().then((response) => {
        this.languageOptions = response.data;
      });
    },
    //打开语言设置
    openSettings(row) {
      this.settingsForm.id = row.id;
      this.settingsForm.country = row.country || "";
      this.settingsForm.languageId = row.languageId || "";
      if (this.settingsForm.languageId) {
        this.updateCountries();
      }
      this.settingsForm.timeZoneId = row.timeZoneId || "";
      this.settingsVisible = true;
    },
    formatLanguage(languageId) {
      const language = this.languageOptions.find(
        (item) => item.id === languageId
      );
      if (language) {
        const parts = language.name.split("(");
        if (parts.length === 2) {
          const country = parts[1].replace(")", "").trim();
          const languageName = parts[0].trim();
          return `${country} ${languageName}`;
        }
        return language.name;
      }
      return "--";
    },
    handleComponentClick() {
      const clientKey = process.env.VUE_APP_CLIENT_KEY;
      const redirectURI = `${window.location.origin}/oauth/tiktok/callback`;
      // 测试环境
      // const clientKey = 'sbawfio28umpa2v7is';
      // const redirectURI = 'https://pub.grds.cn/oauth/tiktok/callback';//回调地址
      // 生产环境
      // const clientKey = 'aw72s25ccj1raw6i';
      // const redirectURI = 'https://pub.infonity.ai/oauth/tiktok/callback';//回调地址
      const scope =
        "user.info.basic,video.publish,video.upload,artist.certification.read,artist.certification.update,user.info.profile,user.info.stats,video.list";
      const tiktokAuthURL = `https://www.tiktok.com/v2/auth/authorize/?client_key=${clientKey}&redirect_uri=${encodeURIComponent(
        redirectURI
      )}&response_type=code&state=1&scope=${encodeURIComponent(scope)}`; // window.location.href = tiktokAuthURL
      window.open(tiktokAuthURL, "_blank", "width=800,height=600");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 查看
    handleView(row) {
      this.profileDrawer = true;
      this.$nextTick().then((rs) => {
        this.$refs.profileDeatil.init(row.profileId, false);
      });
    },
    // 编辑
    handleEdit(row) {
      this.profileDrawer = true;
      this.$nextTick().then((rs) => {
        this.$refs.profileDeatil.init(row.profileId, true);
      });
    },
    updateDetail() {
      this.profileDrawer = false;
      this.getList();
    },
    // 解绑
    handleUnbind(row) {
      const ids = row.id;
      const usernames = row.username;
      this.$modal
        .confirm(
          this.$t("account.tipUnbind1") +
            usernames +
            this.$t("account.tipUnbind2")
        )
        .then(() => {
          this.deleteLoading = true;
          return delAccount(ids);
        })
        .then(() => {
          this.getList();
          this.getQuota();
          this.$modal.msgSuccess(this.$t("account.successfully"));
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    // 删除
    handleDelete(row) {
      const ids = row.profileId;
      this.$modal
        .confirm(this.$t("account.tipDelete"))
        .then(() => {
          this.deleteLoading = true;
          return delProfile(ids);
        })
        .then(() => {
          this.getList();
          this.getQuota();
          this.$modal.msgSuccess(this.$t("account.tipDeleteSuccessfully"));
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    updateCountries() {
      // 更新国家选项
      const selectedData = this.languageOptions.find(
        (item) => item.id === this.settingsForm.languageId
      );
      this.settingsForm.country = selectedData.countryName || "";
      if (selectedData) {
        this.timeOptions = selectedData.timeZones.map((timeZone) => ({
          label: timeZone.rawFormat,
          value: timeZone.id,
        }));
      } else {
        this.timeOptions = [];
      }
      // 重置国家和时区格式选择
      if (selectedData && selectedData.timeZones.length === 1) {
        this.settingsForm.timeZoneId = selectedData.timeZones[0].id;
      } else {
        this.settingsForm.timeZoneId = "";
      }
    },
    saveSettings() {
      // Save settings logic
      this.$refs.settingsForm.validate((valid) => {
        if (valid) {
          this.settingsVisible = false;
          editAccount(this.settingsForm).then((response) => {
            this.$modal.msgSuccess(this.$t("account.settingsSaved"));
            this.getList();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.account-container {
  padding: 16px 24px;
}
.account-content {
  padding-bottom: 16px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
