<!-- 更多模板 -->
<template>
  <el-drawer
    class="publicDrawer"
    :size="700"
    :visible.sync="localVisible"
    :before-close="handleBeforeClose"
    :with-header="true"
    :show-close="false"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ $t('template.moreTemplates') }}</div>
        <i
          class="el-icon-close"
          @click="handleCancel"
        ></i>
      </div>
    </div>
    <div class="container-all">
      <div class="template-title">{{ $t('template.simplified') }}：</div>
      <div class="flex-row f-wrap">
        <div
          v-for="template in templatesSimple"
          :key="template.id"
          class="template-more-item"
        >
          <TemplateItem
            :template="template"
            @view-template="viewTemplate"
            @apply-template="applyTemplate"
          />
        </div>
      </div>
      <div class="template-title">{{ $t('template.enriched') }}：</div>
      <div class="flex-row f-wrap">
        <div
          v-for="template in templatesRich"
          :key="template.id"
          class="template-more-item"
        >
          <TemplateItem
            :template="template"
            @view-template="viewTemplate"
            @apply-template="applyTemplate"
          />
        </div>
      </div>
    </div>

    <templateItemDetail
      :visible.sync="detailVisible"
      :template="templateDetail"
      :isInner="true"
      @confirm="applyTemplate"
    />
  </el-drawer>
</template>

<script>
import { listTemplate } from "../../api/aiClip/aiTemplate";
import TemplateItem from "./templateItem.vue";
import TemplateItemDetail from "./templateItemDetail.vue";
export default {
  components: {
    TemplateItem,
    TemplateItemDetail,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    template: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.getTemplateList("精简版");
    this.getTemplateList("丰富版");
  },
  data() {
    return {
      localVisible: this.visible,
      templatesSimple: [], //精简版数据
      templatesRich: [], //丰富版数据
      detailVisible: false, // 控制模板详情的显示与隐藏
      templateDetail: {}, // 模板详情数据
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
  },
  methods: {
    getTemplateList(type) {
      listTemplate({
        type: type,
      }).then((response) => {
        if (type === "精简版") {
          this.templatesSimple = response.data;
        } else {
          this.templatesRich = response.data;
        }
      });
    },
    viewTemplate(template) {
      this.templateDetail = template;
      this.detailVisible = true;
    },
    applyTemplate(template) {
      this.$emit("apply-template", template);
      this.close();
    },
    handleBeforeClose(done) {
      this.close();
      done();
    },
    handleCancel() {
      this.close();
    },
    handleConfirm() {
      this.$emit("confirm", this.template);
      this.close();
    },
    close() {
      this.localVisible = false;
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-all {
  padding: 24px;
  overflow-y: scroll;
}
.template-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}
.template-more-item {
  width: calc(50% - 12px);
  margin: 0 24px 24px 0;
  &:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
