var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "template-card",
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _c("div", { staticClass: "template-title" }, [
        _vm._v(_vm._s(_vm.item.title)),
      ]),
      _c("div", { staticClass: "template-des" }, [
        _vm._v(_vm._s(_vm.item.description)),
      ]),
      _c(
        "div",
        { staticClass: "steps" },
        _vm._l(_vm.item.details, function (step, i) {
          return _c("div", { key: i, staticClass: "mt5" }, [
            _c("span", { staticClass: "step" }, [_vm._v(_vm._s(step.label))]),
            i < _vm.item.details.length - 1
              ? _c("span", { staticClass: "step-arrow" }, [_vm._v("→")])
              : _vm._e(),
          ])
        }),
        0
      ),
      _vm.hover
        ? _c("div", { staticClass: "overlay flex-row-center f-around" }, [
            _c(
              "div",
              {
                staticClass: "flex-row-center pointer btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("view-template", _vm.item)
                  },
                },
              },
              [
                _c("i", { staticClass: "iconfont icon-a-xialajiantoubeifen3" }),
                _vm._v(" " + _vm._s(_vm.$t("view")) + " "),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flex-row-center pointer btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("apply-template", _vm.item)
                  },
                },
              },
              [
                _c("i", { staticClass: "iconfont icon-dagou" }),
                _vm._v(" " + _vm._s(_vm.$t("template.apply")) + " "),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }