<!-- 编辑视频 -->
<template>
  <div>
    <div class="flex-row-center f-between w-100 mt20">
      <div class="flex-row-center">
        <div class="headline-title flex-row-center mr16">
          <img
            src="../../assets/images/aiCutting/copywriting.png"
            alt=""
            class="img-title"
          >
          {{$t('editVideo.editVideo')}}
        </div>
        <div
          v-for="(item, index) in sceneList"
          :key="index"
          class="copywriting-item"
          :class="{ active: index === activeStep }"
          @click="selectStep(index)"
        >
          {{$t('editVideo.video')}}{{ index + 1 }}
          <i
            class="el-icon-close"
            v-if="sceneList.length > 1"
            @click="deleteItem(index)"
          ></i>
        </div>
      </div>
      <div class="flex-row-center">
        <el-button
          class="cancelBtn mr16"
          @click="last"
        >{{$t('register.previous')}}</el-button>
        <el-button
          :loading="finishLoading"
          class="submitBtn"
          @click="makeVideo"
        >{{$t('editVideo.generateVideo')}}</el-button>
      </div>
    </div>
    <div class="video-all flex-row mt20">
      <div class="vidoe-tools f-shrink">
        <div
          v-for="(item, index) in menu"
          :key="index"
          class="tool-item"
          :class="{ active: index === currentMenu }"
          @click="selectMenu(index)"
        >
          <i
            class="iconfont"
            :class="item.icon"
          ></i>
          {{ item.title }}
        </div>
      </div>
      <div
        class="video-middle-right flex-row"
        v-if="textList[activeStep]"
      >
        <div class="middle-scene">
          <div v-show="currentMenu === 0">
            <div
              v-loading="loading"
              v-for="(template, index) in textList[activeStep].videoList"
              :key="index"
              class="template-card-item"
            >
              <VideoItem
                :template="template"
                :selected-item="selectedItem"
                :languageCode="languageCode"
                :languageList="languageList"
                @item-selected="selectedVideo"
                @make-video="changeVideo(index, template)"
                @replace-video="replaceVideo(index, template)"
                @delete-video="deleteVideo(index)"
              />
            </div>
          </div>
          <div v-show="currentMenu === 1">
            <div v-if="sceneList[activeStep].music.url">
              <div>{{ $t('editVideo.musicSelect') }}</div>
              <div
                class="flex-row-center"
                style="margin-top: 5px"
              >
                <audio
                  controls
                  :src="sceneList[activeStep].music.url"
                  class="f-grow"
                  ref="audioPlayer"
                ></audio>
                <i
                  class="el-icon-circle-close"
                  @click="delMusic"
                ></i>
              </div>
              <div class="flex-row-center mt5">
                <span style="color: #606266;font-size: 14px;margin-right: 12px;font-weight: 700;">{{ $t('editVideo.musicVolume') }}</span>
                <el-slider
                  :step="0.1"
                  class="f-grow"
                  v-model="sceneList[activeStep].music.volume"
                  :max="1"
                  :min="0"
                  show-input
                ></el-slider>
              </div>
            </div>
            <Material_library
              ref="materialLibrary"
              :skipInit="true"
              :isTextToVideo="true"
              @selectionConfirmed="selectionConfirmed"
            ></Material_library>
          </div>
          <div v-show="currentMenu === 2">
            <!-- 配音音色 -->
            <Voice
              :voiceList="voiceList"
              :value="sceneList[activeStep].aiTts.voice"
              @item-selected="voiceSelected"
              @dubbing-change="dubbingChange"
            />
          </div>
          <div v-show="currentMenu === 3">
            <!-- 字幕 -->
            <Subtitle
              :key="`subtitle-${activeStep}`"
              :value="sceneList[activeStep].subtitle"
              :effectColorStyleList="effectColorStyleList"
              @item-updated="subtitleUpdated"
            />
          </div>
          <div v-show="currentMenu === 4">
            <!-- 标题 -->
            <Title
              :key="`title-${activeStep}`"
              :value="sceneList[activeStep].title"
              :effectColorStyleList="effectColorStyleList"
              :languageCode="languageCode"
              :languageList="languageList"
              @item-updated="titleUpdated"
            />
          </div>
          <div v-show="currentMenu === 5">
            <!-- 转场 -->
            <Transition
              :transitionList="transitionList"
              @item-selected="transitionUpdated"
            />
          </div>
          <div v-show="currentMenu === 6">
            <!-- 设置-Logo、封面、封底 -->
            <Settings
              :value="sceneList[activeStep]"
              @item-updated="settingsUpdated"
            />
          </div>
        </div>
        <div
          class="right-scene flex-center"
          v-if="selectedItem"
        >
          <div class="flex-column-center w-100">
            <div class="flex-row video-container">
              <video
                ref="myVideo"
                @timeupdate="handleTimeUpdate"
                @click="togglePlay"
              >
                <source
                  :src="selectedItem.url"
                  type="video/mp4"
                >
                Your browser does not support the video tag.
              </video>
              <div class="preview">
                <div
                  :style="subtitlePreviewStyle"
                  v-if="selectedItem.text && selectedItem.text.length > 0"
                >{{ getFirstPart(getTextByValue())  }}</div>
                <div
                  :style="titlePreviewStyle"
                  v-if="sceneList[activeStep] && sceneList[activeStep].title && sceneList[activeStep].title.content && sceneList[activeStep].title.content.length > 0"
                >{{ getTextByTitleValue() }}</div>
              </div>
              <!-- <i v-if="isPlaying" class="el-icon-video-pause" style="margin-top: -30px;"></i> -->
              <div class="icon-style flex-center">
                <i
                  v-if="!isPlaying"
                  class="el-icon-video-play"
                  @click="togglePlay"
                ></i>
                <i
                  v-if="isPlaying"
                  class="el-icon-video-pause"
                  @click="togglePlay"
                ></i>
              </div>
              <div class="video-language flex-column f-end">
                <div
                  v-for="it in selectLanguageCode"
                  :key="it"
                  :class="['video-language-item', it == currentLanguage && 'active-language-item']"
                  @click="changeLanguage(it)"
                >{{ getLanguageName(it) }}</div>
              </div>
            </div>
            <input
              type="range"
              min="0"
              :max="selectedItem.duration"
              step="0.01"
              v-model="currentTime"
              @input="seekVideo"
            />
            <div class="time-duration">{{ secondsFormatTime(currentTime) }} / {{ secondsFormatTime(selectedItem.duration) }}</div>
          </div>

        </div>
      </div>
    </div>
    <el-drawer
      :title="$t('mixing.materia')"
      size="65%"
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
    >
      <Material_library
        v-if="selectedItem"
        ref="videoMaterial"
        :skipInit="true"
        :timeLength="selectedItem.duration || 1"
        @selectionConfirmed="videoConfirmed"
        @cancel="drawer=false"
      />
    </el-drawer>
  </div>
</template>
<script>
import { analysisVideo, generateVideo } from "../../api/aiClip/aiClip";
import VideoItem from "./videoItem.vue";
import Voice from "./Voice.vue";
import Subtitle from "./Subtitle.vue";
import Title from "./Title.vue";
import Transition from "./Transition.vue";
import Settings from "./Settings.vue";
import Material_library from "../aivideo/material.vue";
import {
  getAzureVoice,
  getEffectColorStyleList,
  getLanguages,
  getTransitionsList,
} from "../../api/ai/ali";
export default {
  name: "EditVideo",
  components: {
    VideoItem,
    Material_library,
    Voice,
    Subtitle,
    Title,
    Transition,
    Settings,
  },
  data() {
    return {
      textList: [], //上个页面传过来的所有数据
      sceneList: [], //需要上传接口的格式
      activeStep: 0,
      menu: [
        {
          title: this.$t("videoMenu.scenes"),
          icon: "icon-shipin1",
        },
        {
          title: this.$t("videoMenu.music"),
          icon: "icon-yinle",
        },
        {
          title: this.$t("videoMenu.voiceOver"),
          icon: "icon-peiyin",
        },
        {
          title: this.$t("videoMenu.subtitles"),
          icon: "icon-wenzi",
        },
        {
          title: this.$t("videoMenu.text"),
          icon: "icon-zimu",
        },
        {
          title: this.$t("videoMenu.transitions"),
          icon: "icon-zhuanchang",
        },
        {
          title: this.$t("videoMenu.setting"),
          icon: "icon-moban",
        },
      ],
      currentMenu: 0,
      loading: false,
      finishLoading: false,
      currentTime: 0, //当前视频播放的时间
      isPlaying: false, // 用于控制播放和暂停状态
      selectedItem: null, // 用于存储选中的视频 item
      voiceList: [], //音色列表
      effectColorStyleList: [], //花字效果
      transitionList: [], //转场效果
      drawer: false, //视频素材选择的抽屉
      videoIndex: 0, //当前选择的视频素材的索引
      languageCode: null,
      languageList: [],
      selectLanguageCode: [], //可供切换的语言——在languageCode上增加了中文
      currentLanguage: "zh", //当前切换的语言
    };
  },
  computed: {
    subtitlePreviewStyle() {
      const settings = this.sceneList[this.activeStep].subtitle || {};
      // const maxTop = 560;
      const maxTop = window.innerHeight - 400;
      console.log("maxTop", maxTop);

      const lines =
        settings.alignment === "TopCenter"
          ? 12
          : settings.alignment === "CenterCenter"
          ? 6
          : 1;
      const y = settings.Y || 0.9; // 默认 0.5 居中

      let top = "auto";
      let bottom = "auto";
      let transform = "translateX(-50%)";

      if (y === 0.1) {
        top = `${y * maxTop}px`;
      } else if (y === 0.5) {
        top = "50%";
        transform += " translateY(-50%) "; // 0.5 居中
      } else {
        bottom = `${(1 - y) * maxTop}px`;
      }
      const style = {
        fontFamily: settings.font || "SimSun",
        fontSize: `${settings.fontSize / 3 || 16}px`,
        // letterSpacing: `${settings.spacing || 0}px`,
        color: settings.fontColor || "#ffffff",
        // textShadow: `${settings.outline || 0}px ${settings.outline || 0}px ${settings.outlineColour || '#000000'}`,
        // opacity: (settings.opacity || 100) / 100,
        fontStyle: (settings.FontFaces || []).includes("Italic")
          ? "italic"
          : "normal",
        fontWeight: (settings.FontFaces || []).includes("Bold")
          ? "bold"
          : "normal",
        textDecoration: (settings.FontFaces || []).includes("Underline")
          ? "underline"
          : "none",
        position: "absolute",
        // top: `${Math.min(settings.Y * maxTop, maxTop)}px`,
        top,
        bottom,
        transform: transform.trim(),
        textAlign: "center",
        left: "50%",
        // transform: "translateX(-50%)",
        overflow: "hidden", // 隐藏超出范围的文本
        textOverflow: "ellipsis", // 超出部分显示省略号
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": lines,
        padding: "0 10px", // 调整左右边距
        width: maxTop * (9 / 16) - 20 + "px",
        zIndex: 9,
      };
      return style;
    },
    titlePreviewStyle() {
      const settings = this.sceneList[this.activeStep].title || {};
      // const maxTop = 560;
      const maxTop = window.innerHeight - 400;
      const alignment = settings.position || "center";
      const y = settings.y || 0.9; // 默认 0.5 居中

      let top = "auto";
      let bottom = "auto";
      let transform = "";

      if (y === 0.1) {
        top = `${y * maxTop}px`;
      } else if (y === 0.5) {
        transform += "translateY(-50%) "; // 0.5 居中
      } else {
        bottom = `${(1 - y) * maxTop}px`;
      }
      if (alignment === "center") {
        transform += "translateX(-50%)";
      } else if (alignment === "right") {
        transform += "translateX(0)";
      }
      return {
        fontFamily: settings.font || "SimSun",
        fontSize: `${settings.fontSize / 2.5 || 16}px`,
        letterSpacing: `${settings.spacing || 0}px`,
        color: settings.fontColor || "#ffffff",
        textShadow: `${settings.outline || 0}px ${settings.outline || 0}px ${
          settings.outlineColour || "#000000"
        }`,
        opacity: settings.fontColorOpacity,
        fontStyle: (settings.fontFaces || []).includes("Italic")
          ? "italic"
          : "normal",
        fontWeight: (settings.fontFaces || []).includes("Bold")
          ? "bold"
          : "normal",
        textDecoration: (settings.fontFaces || []).includes("Underline")
          ? "underline"
          : "none",
        position: "absolute",
        // top: `${Math.min(settings.y * maxTop, maxTop)}px`,
        top,
        bottom,
        transform: transform.trim(),
        textAlign: alignment,
        left:
          alignment === "center" ? "50%" : alignment === "left" ? "0" : "auto",
        right: alignment === "right" ? "0" : "auto",
        // transform:
        //   alignment === "center"
        //     ? "translateX(-50%)"
        //     : alignment === "right"
        //     ? "translateX(0)"
        //     : "none",
        padding: "0 10px", // 调整左右边距
        width: maxTop * (9 / 16) - 20 + "px",
      };
    },
  },
  created() {
    // this.init(sessionStorage.getItem('editVideo'))
    this.getLanguage();
  },
  methods: {
    // 根据语言值获取中文
    getLanguageName(code) {
      const item = this.languageList.find((lang) => lang.bdCode === code);
      return item ? item.language : code;
    },
    // 根据选中的语言值获取对应的文本——字幕
    getTextByValue() {
      const result = this.selectedItem.text.find(
        (item) => item.value === this.currentLanguage
      );
      return result ? result.text : null; // 如果找到，返回 text；否则返回 null
    },
    // 根据选中的语言值获取对应的文本——标题
    getTextByTitleValue() {
      const result = this.sceneList[this.activeStep].title.content.find(
        (item) => item.value === this.currentLanguage
      );
      return result ? result.text : null; // 如果找到，返回 text；否则返回 null
    },
    changeLanguage(value) {
      this.currentLanguage = value;
    },
    getFirstPart(input) {
      // 正则表达式匹配第一个非字母、非数字、非空白符的符号，并取符号前的数据
      const match = input.match(
        /^[^，。！；：@#\$%\^\&\*\(\)\[\]\{\}\+\-=<>\/\\\|\?`~'"\s]+/
      );
      return match ? match[0] : input; // 返回匹配到的内容
    },
    init(data, languageCode) {
      // this.textList = JSON.parse(sessionStorage.getItem('editVideo'))
      this.languageCode = languageCode || [];
      this.selectLanguageCode = ["zh", ...this.languageCode];
      this.textList = JSON.parse(data);
      this.selectedVideo(this.textList[0].videoList[0]);
      console.log(this.textList);
      this.convertListToSceneList(JSON.parse(JSON.stringify(this.textList)));
    },
    getLanguage() {
      getLanguages().then((response) => {
        this.languageList = response.data;
      });
    },
    transitionUpdated(value) {
      if (value) {
        this.sceneList[this.activeStep].transition = {
          Duration: 2,
          SubType: value,
        };
      } else {
        this.sceneList[this.activeStep].transition = null;
      }
      console.log("this.sceneList", this.sceneList);
    },
    titleUpdated(value) {
      // this.sceneList[this.activeStep].title = value
      this.$set(this.sceneList[this.activeStep], "title", value);
      console.log("this.sceneList", this.sceneList);
    },
    settingsUpdated(value) {
      this.$set(this.sceneList, this.activeStep, {
        ...this.sceneList[this.activeStep],
        ...value,
      });
      console.log("this.sceneList", this.sceneList);
    },
    subtitleUpdated(value) {
      // this.sceneList[this.activeStep].subtitle = value
      this.$set(this.sceneList[this.activeStep], "subtitle", value);
      console.log("this.sceneList", this.sceneList);
    },
    voiceSelected(value) {
      console.log("voiceSelected", value);
      this.sceneList[this.activeStep].aiTts.voice = value.shortName;
      this.sceneList[this.activeStep].aiTts.language = value.locale;
      console.log("this.sceneList", this.sceneList);
    },
    // 配音开启or关闭
    dubbingChange(value) {
      this.sceneList[this.activeStep].aiTts.dubbing = value;
    },
    // 获取音色列表
    getVoiceList() {
      getAzureVoice().then((res) => {
        this.voiceList = res.data.filter((voice) =>
          voice.name.startsWith("中文")
        );
        // if ("多语种" in this.voiceList) {
        //   delete this.voiceList["多语种"];
        // }
        // if ("英文" in this.voiceList) {
        //   delete this.voiceList["英文"];
        // }
      });
    },
    //花字效果
    getEffectColorStyleList() {
      getEffectColorStyleList().then((res) => {
        this.effectColorStyleList = res.data;
      });
    },
    // 获取转场列表
    getTransitionsList() {
      getTransitionsList().then((res) => {
        this.transitionList = res.data;
      });
    },
    //选中music
    selectionConfirmed(items) {
      if (items.length == 1) {
        this.sceneList[this.activeStep].music.url = items[0].url;
      }
    },
    delMusic() {
      this.sceneList[this.activeStep].music.url = "";
    },
    selectedVideo(item) {
      this.selectedItem = item; // 更新选中项
      this.isPlaying = false; // 重置播放状态
      console.log("this.selectedItem", JSON.stringify(this.selectedItem));
      this.$nextTick(() => {
        const video = this.$refs.myVideo;
        if (video) {
          video.load(); // 重新加载新的视频文件
        }
      });
    },
    //开始or暂停播放
    togglePlay() {
      const video = this.$refs.myVideo;
      if (this.currentTime == this.selectedItem.duration) {
        this.currentTime = 0;
        video.currentTime = 0;
      }
      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    handleTimeUpdate() {
      const video = this.$refs.myVideo;
      const duration = this.selectedItem.duration;
      this.currentTime = Math.min(video.currentTime, duration);

      // 当视频播放到达自定义时长时暂停
      if (this.currentTime >= duration) {
        video.pause();
      }
    },
    seekVideo(event) {
      // 使视频进度条和显示时间一致
      this.$refs.myVideo.currentTime = event.target.value;
    },
    //按照上传需求更改数据结构
    convertListToSceneList(list) {
      this.sceneList = list.map((item) => {
        return {
          isMute: true,
          aiTts: {
            speed: 1,
            voice: "zh-CN-XiaoxiaoNeural",
            language: "locale",
            volume: 1,
            dubbing: true,
          },
          outputMediaConfig: {
            height: 1920,
            width: 1080,
          },
          music: {
            speed: 1,
            url: "",
            volume: 0.1,
          },
          subtitle: {
            open: true,
            FontFaces: [],
            fontFace: {
              Italic: false,
              Underline: false,
              Bold: false,
            },
            Y: 0.9,
            position: "bottom",
            alignment: "BottomCenter",
            font: "SimSun",
            fontColor: "#ffffff",
            fontSize: 53,
            fontSizeType: "normal",
            openEffectColorStyle: false,
            effectColorStyle: "", //花字
          },
          textList: item.videoList.map((video) => ({
            // effects: [
            //   {
            //     gain: 0,
            //     type: "Volume"
            //   }
            // ],
            text: video.text,
            title: item.title || "",
            url: video.url,
          })),
          title: {
            adaptMode: "AutoWrapAtSpaces",
            content: item.title || [],
            fontColorOpacity: 1, //透明度传的之后0-1
            x: 0,
            y: 0.1,
            outline: 0, //描边宽度
            outlineColour: "#ffffff", //描边颜色
            spacing: 0, //字间距
            fontSize: 53, //字体大小
            fontSizeType: "normal",
            font: "SimSun", //字体
            fontColor: "#ffffff", //字体颜色
            alignment: "TopCenter",
            position: "center",
            fontFaces: [], //字体样式
            fontFace: {
              Italic: false,
              Underline: false,
              Bold: false,
            },
            openEffectColorStyle: false,
            effectColorStyle: "", //花字
          },
          transition: null,
          coverUrl: "", // 封面
          backCoverUrl: "", // 封底
          coverUrlHover: false, // 封面 hover
          backCoverUrlHover: false, // 封底 hover
          logoTrackClipsBo: {
            type: "GlobalImage",
            x: "20",
            y: "20",
            width: 200,
            height: 200,
            mediaUrl: "",
            position: "left-top",
            hover: false,
          },
        };
      });
      console.log("this.sceneList", this.sceneList);
    },
    //切换菜单
    selectMenu(index) {
      this.currentMenu = index;
      if (this.currentMenu !== 0) {
        this.$refs.myVideo.pause();
      }
      if (this.currentMenu === 1) {
        this.$nextTick().then((rs) => {
          this.$refs.materialLibrary.init(2);
        });
      } else if (this.currentMenu === 2) {
        this.getVoiceList();
      } else if (this.currentMenu === 3) {
        this.getEffectColorStyleList();
      } else if (this.currentMenu === 4) {
        this.getEffectColorStyleList();
      } else if (this.currentMenu === 5) {
        this.getTransitionsList();
      } else if (this.currentMenu === 6) {
      }
    },
    //切换视频
    selectStep(index) {
      this.activeStep = index;
      this.selectedVideo(this.textList[this.activeStep].videoList[0]);
    },
    //删除整个视频
    deleteItem(index) {
      this.sceneList.splice(index, 1);
      this.textList.splice(index, 1);
    },
    //智能生成
    changeVideo(index, item) {
      this.loading = true;
      let zhTextItem = item.text.find((item) => item.value === "zh");
      let zhText = zhTextItem ? zhTextItem.text : null;
      let zhTitleItem =
        this.textList[this.activeStep].title &&
        this.textList[this.activeStep].title.find(
          (item) => item.value === "zh"
        );
      let zhTitle = zhTitleItem ? zhTitleItem.text : null;

      analysisVideo({
        paragraphList: [
          {
            title: zhTitle,
            analysisTextList: [
              {
                text: zhText,
              },
            ],
          },
        ],
        languageCode: this.languageCode,
      })
        .then((response) => {
          console.log(response);
          if (
            response.data &&
            response.data.length > 0 &&
            response.data[0].videoList.length > 0
          ) {
            this.textList[this.activeStep].videoList[index] =
              response.data[0].videoList[0];
            this.recalculateTimes();
            this.selectedVideo(this.textList[this.activeStep].videoList[index]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //替换素材
    replaceVideo(index) {
      this.videoIndex = index;
      this.drawer = true;
      this.$nextTick().then((rs) => {
        this.$refs.videoMaterial.init(6);
      });
    },
    videoConfirmed(items) {
      if (items.length == 1) {
        this.textList[this.activeStep].videoList[this.videoIndex].url =
          items[0].url;
        this.selectedVideo(
          this.textList[this.activeStep].videoList[this.videoIndex]
        );
      }
      this.drawer = false;
    },
    //删除一条视频
    deleteVideo(index) {
      this.textList[this.activeStep].videoList.splice(index, 1);
    },
    //重新计算textList的startTime和endTime值
    recalculateTimes() {
      this.textList.forEach((item) => {
        let accumulatedTime = 0; // 初始化累计时间为0
        item.videoList.forEach((video) => {
          video.startTime = accumulatedTime; // 设置当前视频的开始时间
          accumulatedTime += video.duration; // 累计时间增加当前视频的时长
          video.endTime = accumulatedTime; // 设置当前视频的结束时间
        });
      });
    },
    //上一步
    last() {
      this.finishLoading = false;
      this.$emit("last");
    },
    // 检查场景列表中的所有文本是否包含emoji
    checkSceneListForEmoji(sceneList) {
      for (const scene of sceneList) {
        // 检查 textList 中的 textObj
        for (const textItem of scene.textList) {
          for (const key in textItem.textObj) {
            if (this.hasEmoji(textItem.textObj[key])) {
              return true;
            }
          }
        }
        // 检查 title 中的 contentObj
        if (scene.title && scene.title.contentObj) {
          for (const key in scene.title.contentObj) {
            if (this.hasEmoji(scene.title.contentObj[key])) {
              return true;
            }
          }
        }
      }
      return false;
    },
    makeVideo() {
      if (
        this.sceneList.length > 0 &&
        this.textList.length > 0 &&
        this.sceneList.length === this.textList.length
      ) {
        this.sceneList = this.sceneList.map((scene, index) => {
          const updatedTextList =
            this.textList[index].videoList.map((video) => {
              const textObj = video.text.reduce((acc, item) => {
                acc[item.value] = item.text;
                return acc;
              }, {});
              delete this.textList[index].title;
              return {
                textObj: textObj,
                title: this.textList[index].title,
                url: video.url,
              };
            }) || [];

          const contentObj = scene.title.content
            ? scene.title.content.reduce((acc, item) => {
                acc[item.value] = item.text;
                return acc;
              }, {})
            : null;
          delete scene.title.content;

          if (!scene.coverUrl) {
            scene.coverUrl = null;
          }
          if (!scene.backCoverUrl) {
            scene.backCoverUrl = null;
          }
          if (scene.logoTrackClipsBo && !scene.logoTrackClipsBo.mediaUrl) {
            scene.logoTrackClipsBo = null;
          }
          return {
            ...scene,
            textList: updatedTextList,
            title: {
              ...scene.title,
              contentObj: contentObj,
            },
          };
        });

        // 在生成视频之前检查是否包含emoji
        if (this.checkSceneListForEmoji(this.sceneList)) {
          this.$modal.msgError(this.$t("editVideo.tipEmoji"));
          return;
        }

        this.finishLoading = true;
        generateVideo({
          sceneList: this.sceneList,
          languageCode: this.languageCode,
        })
          .then((response) => {
            console.log(response);
            this.$modal.msgSuccess(this.$t("issueVideo.addSuccess"));
            this.$tab.closePage({
              path: "/newCutting",
              name: "AiCrosscutting",
            });
            setTimeout(() => {
              this.$tab.closeOpenPage({ path: "/videoManager/aiCrosscutting" });
            }, 2000);
          })
          .finally(() => {
            this.finishLoading = false;
          });
      } else {
        this.$modal.msgError(this.$t("editVideo.dataError"));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.headline-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  text-align: left;
  font-style: normal;
  .img-title {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}
.copywriting-item {
  padding: 6px 20px;
  background: #eaf1ff;
  border-radius: 8px;
  margin: 0 16px 0 0;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #0156ff;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  cursor: pointer;
}

.copywriting-item.active {
  background: #0156ff;
  color: #ffffff;
}

.video-all {
  height: calc(100vh - 270px);
  display: flex;
  overflow: hidden;
  .vidoe-tools {
    width: 100px;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    .tool-item {
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 22px;
      text-align: left;
      font-style: normal;

      i {
        color: #0156ff;
        font-size: 24px;
        margin-bottom: 6px;
      }

      &:hover {
        background: #f5f7fa;
      }

      &.active {
        background: #edf1f5;
        // color: #0156ff;
        border-left: 2px solid #016fee;
      }
    }
  }
  .video-middle-right {
    background: #f3f4f6;
    border-radius: 0px 14px 14px 14px;
    flex-grow: 1;
    overflow: hidden;
    padding: 24px 16px;
    .middle-scene {
      width: 55%;
      height: calc(100vh - 318px);
      overflow: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      padding-right: 26px;
    }
    .right-scene {
      width: 45%;
      height: calc(100vh - 318px);
      background: #eaebee;
      border-radius: 14px;
      padding: 16px;

      .video-container {
        position: relative;
        video {
          height: calc(100vh - 400px);
          aspect-ratio: 9 / 16;
          z-index: 1;
          background-color: black;
          border-radius: 12px;
        }
        .preview {
          position: absolute;
          top: 0px;
          // width: 315px;
          // height: 560px;
          height: calc(100vh - 400px);
          aspect-ratio: 9 / 16;
          color: #fff;
          text-align: center;
          line-height: 30px;
          /* margin-top: 20px; */
          overflow: hidden; /* 确保内容不会超出预览框 */
          border-radius: 10px;
          z-index: 2;
        }
        .icon-style {
          position: absolute;
          height: calc(100vh - 400px);
          aspect-ratio: 9 / 16;
          // margin-top: -300px;
          // margin-bottom: 260px;
          i {
            color: white;
            font-size: 40px;
            pointer-events: auto; /* 确保按钮可以交互 */
            z-index: 3; /* 确保图标在视频之上 */
            cursor: pointer;
          }
        }
      }
      input {
        width: calc(100% - 60px);
        margin-top: 10px;
      }
      .time-duration {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 24px;
        text-align: left;
        font-style: normal;
      }
    }
  }
}
.template-card-item {
  width: 100%;
}

.custom-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.video-language {
  // height: 560px;
  height: calc(100vh - 400px);
  margin-left: 10px;
  z-index: 3;
}
.video-language-item {
  font-family: PingFang-SC, PingFang-SC;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #e5e7eb;
  padding: 3px 8px;
}
.active-language-item {
  border: 1px solid #016fee;
  background: linear-gradient(0deg, #0089ff 0%, #6e16d1 100%);
  background-clip: text; /* 无前缀标准写法 */
  -webkit-background-clip: text; /* 兼容 WebKit 浏览器 */
  -webkit-text-fill-color: transparent;
}
</style>
