var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-column h-100" }, [
    _c(
      "div",
      { staticClass: "f-grow", staticStyle: { "overflow-y": "auto" } },
      [
        _c(
          "el-descriptions",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "f-grow rounded-descriptions",
            attrs: { "label-class-name": "my-label", column: 1, border: "" },
          },
          [
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("account.username")) + " "),
                ]),
                _vm.isEdit
                  ? _c(
                      "span",
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "50",
                            placeholder: _vm.$t("tip.input"),
                          },
                          model: {
                            value: _vm.detail.topHeader,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "topHeader", $$v)
                            },
                            expression: "detail.topHeader",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(" " + _vm._s(_vm.detail.topHeader) + " "),
                    ]),
              ],
              2
            ),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("account.role")) + " "),
                ]),
                _vm.isEdit
                  ? _c(
                      "span",
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "50",
                            placeholder: _vm.$t("tip.input"),
                          },
                          model: {
                            value: _vm.detail.roleName,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "roleName", $$v)
                            },
                            expression: "detail.roleName",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(" " + _vm._s(_vm.detail.roleName) + " "),
                    ]),
              ],
              2
            ),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("account.description")) + " "),
                ]),
                _vm.isEdit
                  ? _c(
                      "span",
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: "200",
                            "show-word-limit": "",
                            rows: 3,
                          },
                          model: {
                            value: _vm.detail.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "description", $$v)
                            },
                            expression: "detail.description",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(" " + _vm._s(_vm.detail.description) + " "),
                    ]),
              ],
              2
            ),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("account.bindTime")) + " "),
                ]),
                _vm._v(" " + _vm._s(_vm.detail.bindingTime) + " "),
              ],
              2
            ),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("account.bindPlatform")) + " "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "mt8 social",
                    staticStyle: { "margin-bottom": "4px" },
                  },
                  _vm._l(_vm.detail.socialList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "flex-row-center mb20" },
                      [
                        _c("div", { staticClass: "w-50 flex-row-center" }, [
                          _c("i", {
                            staticClass: "iconfont mr5 font14",
                            class: [
                              "icon-" + item.platform + "-normal",
                              item.platform,
                            ],
                          }),
                          _vm._v(" " + _vm._s(item.platform) + " "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "w-50 flex-row-center" },
                          [
                            _c("el-avatar", {
                              staticClass: "mr8 f-shrink",
                              attrs: { src: item.userImage, size: 32 },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "f-grow",
                                staticStyle: { "text-align": "left" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.platform == "facebook"
                                        ? item.pageName
                                        : item.username
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "f-shrik flex-center" },
      [
        _c(
          "el-button",
          {
            staticClass: "cancelBtn",
            on: {
              click: function ($event) {
                return _vm.$emit("cancel")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        ),
        _c(
          "el-button",
          {
            staticClass: "submitBtn",
            attrs: { loading: _vm.btLoading },
            on: { click: _vm.handleSure },
          },
          [_vm._v(_vm._s(_vm.$t("sure")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }