<!-- 编辑文稿 -->
<template>
  <div>
    <div class="headline-title flex-row-center">
      <img
        src="../../assets/images/aiCutting/copywriting.png"
        alt=""
        class="img-title"
      >
      {{$t('tab.editManuscript')}}
    </div>
    <div class="flex-row-center f-wrap mt20">
      <div
        v-for="(item, index) in copywritings"
        :key="index"
        class="copywriting-item"
        :class="{ active: index === activeStep }"
        @click="selectStep(index)"
      >
        {{$t('editManuscripts.script')}}{{ index + 1 }}
      </div>
    </div>
    <div class="little-title">{{$t('editManuscripts.title')}}：</div>
    <el-input
      v-if="copywritings[activeStep]"
      v-model="copywritings[activeStep].title"
      class="title-input"
      maxlength="50"
      :placeholder="$t('editManuscripts.enterTitle')"
    />
    <div class="little-title">{{$t('editManuscripts.scriptSegmentation')}}：</div>
    <div
      class="preview-list"
      v-if="copywritings[activeStep]"
      v-loading="selectLoading"
    >
      <div
        v-for="(it, position) in copywritings[activeStep].analysisTextList"
        :key="position"
        class="post-item flex-row-center"
      >
        <span class="item-num flex-center"> {{ position + 1 }} </span>
        <el-input
          class="f-grow item-input"
          v-model="it.text"
          maxlength="200"
        />
        <el-select
          v-if="typeList.length"
          v-model="it.materialTypeId"
          :placeholder="$t('editManuscripts.grouping')"
          class="item-material"
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <div class="flexOption">
              <div class="optionLeft">{{ item.name }}</div>
              <!-- style="float: right; color: #8492a6; font-size: 13px" -->
              <div
                class="optionRight"
                v-if="item.id != 0"
              >{{ item.materialCount }} {{$t('editManuscripts.material')}}</div>
            </div>
          </el-option>
        </el-select>
        <!-- <treeselect
            v-model="it.materialTypeId"
            :options="typeList"
            :show-count="true"
            :normalizer="normalizer"
            :clearable="false"
            :placeholder="$t('editManuscripts.grouping')"
            style="width: 150px;margin: 0 5px;"
          /> -->
        <i
          class="iconfont icon-delete"
          v-if="copywritings[activeStep].analysisTextList.length > 1"
          @click="deleteItem(activeStep,position)"
          style="cursor: pointer;"
        ></i>
      </div>
    </div>
    <div class="little-title">{{$t('editManuscripts.addLanguage')}}：</div>
    <el-select
      v-model="languageCode"
      class="language-select w-100"
      multiple
      multiple-limit="3"
      filterable
      clearable
      :placeholder="$t('issueItem.translateLanguage')"
      :default-first-option="true"
    >
      <el-option
        v-for="item in languageList"
        :key="item.bdCode"
        :label="item.language"
        :value="item.bdCode"
      />
    </el-select>
    <div
      class="flex-center"
      style="margin-top: 80px;"
    >
      <el-button
        class="cancelBtn"
        @click="last"
      >{{$t('register.previous')}}</el-button>
      <el-button
        class="submitBtn"
        :loading="loading"
        @click="next"
      >{{$t('editManuscripts.nextStep')}}</el-button>
    </div>
  </div>
</template>
<script>
import { listMaterialType } from "../../api/ai/materialType";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { analysisVideo } from "../../api/aiClip/aiClip";
import { getLanguages } from "../../api/ai/ali";

export default {
  name: "EditManuscripts",
  components: {
    Treeselect,
  },
  data() {
    return {
      copywritings: [],
      activeStep: 0,
      typeList: [], //素材分组列表
      loading: false,
      languageCode: null, //翻译语言
      languageList: [],
      dialogVisible: false,
      selectLangList: [],
      selectLoading: false,
    };
  },
  mounted() {
    this.getLanguage();
    // this.init(sessionStorage.getItem('editManuscriptsData'))
  },
  methods: {
    // closeLang(code) {
    //   this.selectLangList = this.selectLangList.filter(item => item.dictValue !== code)
    // },
    // addLangOpen() {
    //   if (this.selectLangList.length) {
    //     this.languageList.forEach(selectItem => {
    //       const exists = this.selectLangList.some(item => item.dictValue === selectItem.dictValue)
    //       if (exists) {
    //         selectItem.select = true
    //       } else {
    //         selectItem.select = false
    //       }
    //     })
    //   } else {
    //     this.languageList.forEach(item => item.select = false)
    //   }
    //   this.dialogVisible = true
    // },
    // submitTagList() {
    //   this.selectLangList = this.languageList.filter(item => item.select === true)
    //   this.dialogVisible = false
    // },
    // selectTagFun(code) {
    //   this.languageList.forEach(item => {
    //     if (item.dictValue === code) {
    //       item.select = !item.select
    //     }
    //   })
    // },
    init(data) {
      // let list = JSON.parse(sessionStorage.getItem('editManuscriptsData'));
      let list = JSON.parse(data);
      console.log(list);
      this.getMaterialTypeList();
      this.copywritings = list.map((item) => {
        return {
          title: item.title || "",
          analysisTextList: item.paragraphList.map((text, index) => ({
            text: text,
            materialTypeId: 0,
          })),
        };
      });
      // 如果不再需要，可以在读取后删除
      // sessionStorage.removeItem('editManuscriptsData');
    },
    getLanguage() {
      getLanguages().then((response) => {
        const newList = response.data.map((item) => {
          return { ...item, select: false };
        });
        const oftenZero = newList.filter((item) => item.isOften === 1);
        const others = newList.filter((item) => item.isOften !== 1);
        this.languageList = oftenZero
          .concat(others)
          .filter((item) => item.bdCode !== "zh");
        console.log("this.languageList", this.languageList);
      });
    },
    //素材分类列表
    getMaterialTypeList() {
      this.selectLoading = true;
      listMaterialType().then((response) => {
        this.typeList = response.data;
        this.typeList.unshift({
          id: 0,
          name: this.$t("basicInfo.materia"),
        });
        this.selectLoading = false;
        console.log(this.typeList);
      });
    },
    deleteItem(index, position) {
      this.copywritings[index].analysisTextList.splice(position, 1);
    },
    selectStep(index) {
      this.activeStep = index;
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },
    last() {
      this.$emit("last");
    },
    next() {
      if (this.languageCode.length > 3) {
        this.$modal.msgError(this.$t("editManuscripts.tipAddLanguage"));
        return;
      }
      this.loading = true;
      const submitData = this.copywritings.map((copywriting) => {
        return {
          ...copywriting,
          analysisTextList: copywriting.analysisTextList.map((textItem) => ({
            ...textItem,
            materialTypeId:
              textItem.materialTypeId == 0 ? null : textItem.materialTypeId,
          })),
        };
      });
      // this.languageCode = this.selectLangList.map(item => {
      //   return item.dictValue
      // })
      analysisVideo({
        paragraphList: submitData,
        languageCode: this.languageCode,
      })
        .then((response) => {
          console.log(response);
          sessionStorage.setItem("editVideo", JSON.stringify(response.data));
          // this.$router.push({ path: '/editVideo' });
          // this.$tab.closeOpenPage({path: "/editVideo"});
          this.$emit("next", JSON.stringify(response.data), this.languageCode);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.headline-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  text-align: left;
  font-style: normal;
  margin-top: 20px;
  .img-title {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}
.copywriting-item {
  padding: 6px 20px;
  background: #eaf1ff;
  border-radius: 8px;
  margin: 0 16px 8px 0;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #0156ff;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  cursor: pointer;
}

.copywriting-item.active {
  background: #0156ff;
  color: #ffffff;
}

.little-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin: 24px 0 8px 10px;
}
.title-input {
  ::v-deep .el-input__inner {
    border-radius: 12px;
  }
}

.preview-list {
  list-style: none;
  padding: 24px 16px 8px 16px;
  background: #f6f8fa;
  border-radius: 12px;

  .post-item {
    margin-bottom: 16px;
    padding: 4px 16px 4px 10px;
    .item-num {
      width: 22px;
      height: 22px;
      background: #0156ff;
      border-radius: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin-right: 16px;
    }
    .item-input {
      ::v-deep .el-input__inner {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
        text-align: left;
        font-style: normal;
        background: transparent;
        border: none;
      }
      // ::v-deep .el-input__inner:focus {
      //   background: #edf1f5;
      //   border-radius: 12px;
      // }
    }
    .item-material {
      margin-left: 16px;
      ::v-deep .el-input__inner {
        border-radius: 8px;
        color: #0156ff;
      }
      ::v-deep .el-input__suffix i {
        color: #0156ff;
      }
    }
    .icon-delete {
      color: #fd2f2f;
      font-size: 14px;
      margin-left: 16px;
    }
  }
  .post-item:hover,
  .post-item:focus {
    background: #edf1f5;
    border-radius: 12px;
  }
}

.language-select {
  ::v-deep .el-input__inner {
    height: 54px !important;
    background: #f6f8fa;
    border-radius: 12px;
  }
}

.flexOption {
  display: flex;
  align-items: center;
  .optionLeft {
    margin-right: 20px;
  }
  .optionRight {
    color: #8492a6;
    font-size: 13px;
  }
}
</style>
