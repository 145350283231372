<!-- 模板列表的item -->
<template>
  <div
    class="template-card"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="template-title">{{ item.title }}</div>
    <div class="template-des">{{ item.description }}</div>
    <div class="steps">
      <div
        v-for="(step, i) in item.details"
        :key="i"
        class="mt5"
      >
        <span class="step">{{ step.label }}</span>
        <span
          v-if="i < item.details.length - 1"
          class="step-arrow"
        >→</span>
      </div>
    </div>
    <div
      v-if="hover"
      class="overlay flex-row-center f-around"
    >
      <div
        class="flex-row-center pointer btn"
        @click="$emit('view-template', item)"
      >
        <i class="iconfont icon-a-xialajiantoubeifen3"></i>
        {{$t('view')}}
      </div>
      <div
        class="flex-row-center pointer btn"
        @click="$emit('apply-template', item)"
      >
        <i class="iconfont icon-dagou"></i>
        {{$t('template.apply')}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "templateItem",
  components: {},
  props: {
    template: {
      type: Object,
      default: () => ({
        hover: false, // 添加 hover 参数的默认值
      }),
    },
  },
  data() {
    return {
      item: this.template,
      hover: this.template.hover || false,
    };
  },
  watch: {
    template: {
      handler(val) {
        this.item = val;
        this.hover = val.hover || false; // 更新 hover
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.template-card {
  position: relative; /* 确保蒙层相对于模板卡片定位 */
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  .template-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #0156ff;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-bottom: 6px;
  }
  .template-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin-bottom: 17px;
  }
}
.steps {
  display: flex;
  flex-wrap: wrap;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.step {
  padding: 1px 4px;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
}
.step-arrow {
  margin: 0 4px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32px;
  background: #f4f5ff;
  border-radius: 0px 0px 12px 12px;
  .btn {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    letter-spacing: 1px;
    text-align: center;
    font-style: normal;
    &:hover {
      color: #0156ff;
    }
  }
  i {
    font-size: 18px;
    margin-right: 4px;
  }
}
</style>
