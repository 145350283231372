var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      staticClass: "publicDrawer",
      attrs: {
        size: 700,
        visible: _vm.localVisible,
        "before-close": _vm.handleBeforeClose,
        "with-header": true,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.$t("template.moreTemplates"))),
          ]),
          _c("i", {
            staticClass: "el-icon-close",
            on: { click: _vm.handleCancel },
          }),
        ]),
      ]),
      _c("div", { staticClass: "container-all" }, [
        _c("div", { staticClass: "template-title" }, [
          _vm._v(_vm._s(_vm.$t("template.simplified")) + "："),
        ]),
        _c(
          "div",
          { staticClass: "flex-row f-wrap" },
          _vm._l(_vm.templatesSimple, function (template) {
            return _c(
              "div",
              { key: template.id, staticClass: "template-more-item" },
              [
                _c("TemplateItem", {
                  attrs: { template: template },
                  on: {
                    "view-template": _vm.viewTemplate,
                    "apply-template": _vm.applyTemplate,
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
        _c("div", { staticClass: "template-title" }, [
          _vm._v(_vm._s(_vm.$t("template.enriched")) + "："),
        ]),
        _c(
          "div",
          { staticClass: "flex-row f-wrap" },
          _vm._l(_vm.templatesRich, function (template) {
            return _c(
              "div",
              { key: template.id, staticClass: "template-more-item" },
              [
                _c("TemplateItem", {
                  attrs: { template: template },
                  on: {
                    "view-template": _vm.viewTemplate,
                    "apply-template": _vm.applyTemplate,
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c("templateItemDetail", {
        attrs: {
          visible: _vm.detailVisible,
          template: _vm.templateDetail,
          isInner: true,
        },
        on: {
          "update:visible": function ($event) {
            _vm.detailVisible = $event
          },
          confirm: _vm.applyTemplate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }