<!-- 账号协同 -->
<template>
  <el-dialog
    class="publicDialog tagRadio"
    :visible.sync="localVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="70%"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ $t('profile.accountCollaboration') }}</div>
        <i class="el-icon-close" @click="close"></i>
      </div>
    </div>
    <div class="flexCenter">
      <el-radio-group v-model="profileType" class="flex-center" style="margin-left: 50px;">
        <el-radio label="new">{{ $t('profile.createCollaboration') }}</el-radio>
        <el-radio label="choose">{{ $t('profile.selectCollaborationID') }}</el-radio>
      </el-radio-group>
    </div>

      <!-- <el-button type="text" @click="createProfile" :disabled="isNew"> + {{ $t('account.addAccount') }}</el-button> -->
    <div class="accountHeight">
    <div class="flexEnd" v-if="profileType === 'choose'">
      <div style="width: 320px;margin-right: 5px;">
        <el-input
          class="account-input"
          v-model="queryParams.username"
          :placeholder="$t('profile.enterTip')"
          clearable
          @keyup.enter.native="handleQuery"
        >
          <i slot="prefix" class="el-input__icon iconfont icon-sousuotubiao" @click="handleQuery"></i>
        </el-input>
      </div>
      <el-tooltip class="item" effect="dark" :content="$t('refresh')" placement="top">
        <el-button style="width: 40px; height: 40px;" size="mini" circle icon="el-icon-refresh" @click="init" />
      </el-tooltip>
    </div>
    <div v-if="profileType === 'new'">
      <el-form ref="profileForm" :model="form" :rules="rules" label-position="top" class="newProfile">
        <el-form-item :label="$t('account.username')" prop="topHeader">
          <el-input v-model="form.topHeader" maxlength="50" :placeholder="$t('profile.enterTipAllowNone')" style="width:100%;"/>
        </el-form-item>
        <el-form-item :label="$t('account.role')" prop="roleName">
          <el-input v-model="form.roleName" maxlength="50" class="inputStyle" :placeholder="$t('account.tipRole')" style="width:100%;">
            <el-radio-group slot="prepend" v-model="form.roleName" class="flex-center">
              <el-radio :label="$t('account.sales')">{{ $t('account.sales') }}</el-radio>
              <el-radio :label="$t('account.operations')">{{ $t('account.operations') }}</el-radio>
              <el-radio :label="$t('account.marketing')">{{ $t('account.marketing') }}</el-radio>
            </el-radio-group>
          </el-input>
          <!-- <el-select v-model="form.roleName" allow-create filterable multiple :placeholder="$t('label.tipSelect')"
                     ref="searchSelect" style="width:100%;">
            <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
          </el-select> -->
        </el-form-item>
        <el-form-item :label="$t('account.description')" prop="description">
          <el-input type="textarea" maxlength="200" show-word-limit :rows="3" v-model="form.description" style="width:100%;"/>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-if="profileType === 'choose'"
      ref="singleTable"
      v-loading="profileLoading"
      :data="profile"
      highlight-current-row
      @current-change="handleCurrentChange"
      header-row-class-name="custom_table_header"
      height="300"
    >
      <el-table-column type="index" :label="$t('account.number')" width="50"/>
      <el-table-column prop="topHeader" :label="$t('profile.topHeader')">
        <template slot-scope="scope">
          <div v-if="scope.row.type === 'new'">
            <el-input
              v-model="scope.row.topHeader"
              maxlength="50"
              clearable
              :placeholder="$t('profile.enterTipAllowNone')"
              @keydown.enter.prevent
              @input="topHeader = scope.row.topHeader"
            />
          </div>
          <div v-else>
            {{ scope.row.topHeader }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="roleName" :label="$t('account.role')"></el-table-column>
      <el-table-column prop="bind" :label="$t('profile.boundSocialMedia')" width="250">
        <template slot-scope="scope">
          <div class="flex-row-center flex-wrap" style="gap: 10px;padding: 5px">
            <i v-for="item in scope.row.bind"
              :key="item"
              class="iconfont mr5 mb5 font18"
              :class="['icon-' + item, item]"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="unbind" :label="$t('profile.availableSocialMedia')" width="250">
        <template slot-scope="scope">
          <div class="flex-row-center f-wrap" style="gap: 10px;padding: 5px">
            <i v-for="item in scope.row.unbind"
              :key="item"
              class="iconfont mr5 mb5 font18 unbind"
              :class="'icon-' + item"
              ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('operate')" align="center">
        <template slot-scope="scope">
          <i style="color: #0092FF;font-size: 20px;" class="iconfont icon-dagou" v-if="(id && scope.row.id === id) || (!id && isNew && scope.row.type === 'new')"></i>
          <!-- <span style="color: #0092FF;font-size: 20px;" v-if="(id && scope.row.id === id) || (!id && isNew && scope.row.type === 'new')">√</span> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-if="profileType === 'choose'"
      v-show="total > 20"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getProfileList"
    />
    </div>
    <!-- <div v-else style="height: 320px;"></div> -->
    <div class="flex-center mt20" >
      <el-button class="cancelBtn btnWidth120" @click="handleCancel">{{ $t('cancel') }}</el-button>
      <el-button v-if="profileType === 'new'" class="submitBtn btnWidth120" type="primary" @click="create">{{ $t('confirm') }}</el-button>
      <el-button v-if="profileType === 'choose'" class="submitBtn btnWidth120" type="primary" @click="choose" :disabled="!id && !topHeader">{{ $t('profile.confirmSelection') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { clearProfile, delProfile, listProfile } from '../../api/account/profile';
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    profileTypes: {
      type: Array,
      default: () => []
    },
    isExceed: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      localVisible: this.visible,
      profile: [],
      profileLoading: false,
      id: undefined,
      profileType: 'new',
      topHeader: undefined,
      deleteLoading: false,
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        username: null,
      },
      isNew: false,//是否已经点击了新建
      form: {
        topHeader: null,
        roleName: null,
        description: null,
      },
      rules: {
        // topHeader: [{ required: true, message: this.$t('tip.input'), trigger: ['blur', 'change'] }],
        // roleName: [{ required: true, message: this.$t('tip.roleNameInput'), trigger: ['blur', 'change'] }],
      }
    };
  },
  created(){
  },
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
  },
  methods: {
    createProfile(){
      this.isNew = true
      this.profile.unshift({
        type: 'new',
        id: null,
        topHeader: this.userInfo.nickName,
        bind: [],
        unbind: this.profileTypes
      })
      this.$refs.singleTable.setCurrentRow(this.profile[0]);
    },
    init(){
      this.clearForm()
      this.getProfileList()
    },
    clearForm(){
      this.form = {
        topHeader: null,
        roleName: null,
        description: null,
      }
    },
    handleCurrentChange(val){
      if(val.id){
        this.id = val.id
        this.topHeader = undefined
      }else{
        this.topHeader = val.topHeader
        this.id = undefined
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getProfileList();
    },
    getProfileList(){
      this.profileLoading = true
      listProfile(this.queryParams).then(response => {
        this.profile = response.rows
        this.total = response.total;
        this.formatProfile()
      }).finally(() => {
        this.profileLoading = false
      })
    },
    formatProfile(){
      this.profile = this.profile.map(item => {
        if (item.activeSocialAccounts) {
          let activeAccounts = JSON.parse(item.activeSocialAccounts); // 解析 activeSocialAccounts 字段
          let bind = activeAccounts;
          let unbind = this.profileTypes.filter(type => !bind.includes(type)); // 找出未绑定的账号
          return { ...item, bind, unbind };
        } else {
          let unbind = this.profileTypes
            return {...item, bind: [], unbind};
        }
      });
    },
    choose(){
      if(!this.id && !this.topHeader){
        this.$modal.msgError(this.$t('profile.pleaseSelectID'));
        return
      }
      this.isNew = false
      this.$emit('action', { id: this.id, topHeader: this.topHeader });
    },
    create(){
      if(this.isExceed){
        this.$modal.msgError(this.$t('account.exceedTip'));
        return
      }
      this.$refs.profileForm.validate((valid) => {
        if (valid) {
          this.$emit('createProfileAction', { form: this.form });
        }
      });
    },
    handleCancel() {
      this.close()
    },
    close(){
      this.handleClear()
      this.isNew = false
      this.localVisible = false;
      this.$emit('update:visible', false);
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id
      this.deleteLoading = true
      this.$modal.confirm(this.$t('profile.confirmDelete')).then(function () {
        return delProfile(ids);
      }).then(() => {
        this.$emit('refresh');
        this.id = undefined
        this.getProfileList();
        this.$modal.msgSuccess(this.$t('account.successfully'));
      }).finally(() => {
        this.deleteLoading = false
      })
    },
    handleClear(){
      clearProfile()
    }
  }
};
</script>

<style lang="scss" scoped>
.tagRadio {
  .template-more-item{
    width: 23%;
    margin: 1%;
  }

  .flexCenter {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 34px;
    .el-radio-group {
      width: 40%;
      justify-content: space-between;
    }
  }
  .accountHeight {
    height: 380px;
    max-height: 380px;

    .flexEnd {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 17px;
    }

    .el-form-item {
      margin-bottom: 28px;
    }
  }
  .newProfile ::v-deep .el-form-item__label {
    padding: 0;
  }
  .el-tag.el-tag--info {
    border-radius: 12px;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0 0 0px 0;
  }
}

</style>
