var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-container" }, [
    _c(
      "div",
      {
        staticClass: "flex-row-center f-between mb16",
        staticStyle: { height: "48px" },
      },
      [
        _c("div", { staticClass: "title-header-text" }, [
          _vm._v(" " + _vm._s(_vm.$t("videoManager.textToVideoList")) + " "),
        ]),
        _vm.quota.videoLength
          ? _c(
              "div",
              { staticClass: "flex-row-center" },
              [
                _c("span", { staticClass: "mr5" }, [
                  _vm._v(_vm._s(_vm.$t("quota.quota")) + "："),
                ]),
                _c("Slider", {
                  attrs: {
                    value: _vm.formatQuota(_vm.quota.useVideoLength) || 0,
                    maxValue: _vm.formatQuota(_vm.quota.videoLength) || 1000,
                  },
                }),
                _c("div", { staticClass: "ml5" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.quota.useVideoLength || 0) +
                      "/" +
                      _vm._s(
                        _vm.quota.videoLength || "1000" + _vm.$t("quota.minute")
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { staticClass: "video-content" },
      [
        _c("div", { staticClass: "flex-row-center f-between w-100" }, [
          _c(
            "div",
            { staticClass: "f-shrink" },
            [
              _c(
                "el-button",
                {
                  staticClass: "gradient-button",
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.createVideo },
                },
                [_vm._v(_vm._s(_vm.$t("videoManager.newTask")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex-row-center" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("refresh"),
                    placement: "top",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-a-danyuanpeizhi6",
                    on: { click: _vm.refresh },
                  }),
                ]
              ),
              _c(
                "el-radio-group",
                {
                  staticClass: "custom-radio-group",
                  model: {
                    value: _vm.styleType,
                    callback: function ($$v) {
                      _vm.styleType = $$v
                    },
                    expression: "styleType",
                  },
                },
                [
                  _c("el-radio", {
                    attrs: { label: "grid", border: _vm.styleType === "grid" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("i", {
                              staticClass: "iconfont font20 icon-grid",
                              class:
                                _vm.styleType === "grid"
                                  ? "gradient-purple"
                                  : "unbind",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("el-radio", {
                    attrs: { label: "list", border: _vm.styleType === "list" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("i", {
                              staticClass: "iconfont font20 icon-list",
                              class:
                                _vm.styleType === "list"
                                  ? "gradient-purple"
                                  : "unbind",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "400px", margin: "0 5px" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "account-input",
                      attrs: {
                        placeholder: _vm.$t("videoManager.tipName"),
                        clearable: "",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleQuery($event)
                        },
                      },
                      model: {
                        value: _vm.queryParams.searchValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "searchValue", $$v)
                        },
                        expression: "queryParams.searchValue",
                      },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "el-input__icon iconfont icon-sousuotubiao",
                        attrs: { slot: "prefix" },
                        on: { click: _vm.handleQuery },
                        slot: "prefix",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
              {
                name: "show",
                rawName: "v-show",
                value: _vm.styleType === "grid",
                expression: "styleType === 'grid'",
              },
            ],
            staticClass: "flex-row f-wrap mt10",
            staticStyle: {
              height: "calc(100vh - 320px)",
              "overflow-y": "auto",
            },
          },
          [
            _vm.videos.length === 0
              ? _c("el-empty", {
                  staticClass: "w-100",
                  attrs: { "image-size": 180 },
                })
              : _vm._e(),
            _vm._l(_vm.videos, function (video, index) {
              return _c("video-grid-item", {
                key: index,
                staticClass: "video-parent",
                attrs: { video: video },
                on: { "delete-video": _vm.deleteVideo },
              })
            }),
          ],
          2
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.styleType === "list",
                expression: "styleType === 'list'",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%", "margin-top": "10px" },
            attrs: {
              "header-row-class-name": "custom_table_header",
              height: "calc(100vh - 320px)",
              data: _vm.videos,
              "row-style": _vm.setRowStyle,
            },
            on: {
              "filter-change": _vm.filterChange,
              "cell-mouse-enter": _vm.cellMouseEnter,
              "cell-mouse-leave": _vm.cellMouseLeave,
            },
          },
          [
            _c("el-empty", {
              attrs: { slot: "empty", "image-size": 180 },
              slot: "empty",
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("editVideo.video"),
                prop: "url",
                "min-width": "122",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "flex-row-center" }, [
                        scope.row.status == 0
                          ? _c(
                              "div",
                              {
                                staticClass: "video-cover video-bg flex-center",
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-shipincaogao",
                                }),
                              ]
                            )
                          : scope.row.status == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "video-cover video-bg flex-center",
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-shipinshengchengzhong",
                                }),
                              ]
                            )
                          : scope.row.status == 2
                          ? _c(
                              "div",
                              {
                                staticClass: "video-cover video-bg flex-center",
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-shipinshengchengshibai",
                                }),
                              ]
                            )
                          : _c("div", [
                              _c(
                                "div",
                                { staticClass: "cellVideo" },
                                [
                                  _c("video", {
                                    staticClass: "video-cover",
                                    attrs: { src: scope.row.url },
                                  }),
                                  _c(
                                    "video-button",
                                    {
                                      attrs: {
                                        url: scope.row.url,
                                        tip: _vm.$t("issueManager.playVideo"),
                                      },
                                      on: {
                                        closeViewVideo: _vm.closeViewVideo,
                                        viewVideo: _vm.viewVideo,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "customButton" }, [
                                        _c("div", { staticClass: "cricle" }, [
                                          _c("i", {
                                            staticClass: "el-icon-caret-right",
                                          }),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "taskName",
                label: _vm.$t("videoManager.name"),
                align: "center",
                "min-width": "100",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: scope.row.taskName,
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "single-line-ellipsis pointer" },
                            [_vm._v(_vm._s(scope.row.taskName))]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "videoTotalLength",
                label: _vm.$t("videoManager.length"),
                align: "center",
                "min-width": "90",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.secondsFormatTime(scope.row.videoTotalLength)
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "status",
                label: _vm.$t("videoManager.status"),
                align: "center",
                "min-width": "270",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "video-status",
                          class: "video-status-" + scope.row.status,
                          style: scope.row.hover ? "opacity: 0" : "opacity: 1",
                        },
                        [
                          scope.row.status == 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("videoManager.statusDraft"))
                                ),
                              ])
                            : _vm._e(),
                          scope.row.status == 1
                            ? _c("div", { staticClass: "flex-column" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("status.generating")) + "..."
                                  ),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "video-status-des" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("status.generatingTip"))
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          scope.row.status == 2
                            ? _c("div", { staticClass: "hidden flex-column" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("issueManager.failed")) +
                                    " "
                                ),
                                scope.row.errorMessage
                                  ? _c(
                                      "span",
                                      { staticClass: "video-status-des" },
                                      [_vm._v(_vm._s(scope.row.errorMessage))]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          scope.row.status == 3
                            ? _c("div", [
                                _vm._v(" " + _vm._s(_vm.$t("model.generated"))),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: _vm.$t("time.createTime"),
                align: "center",
                width: "150",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          style: scope.row.hover ? "opacity: 0" : "opacity: 1",
                        },
                        [_vm._v(_vm._s(scope.row.createTime))]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.hover,
                              expression: "scope.row.hover",
                            },
                          ],
                          staticClass: "flex-row-center operate-hover",
                        },
                        [
                          scope.row.status == 3 && scope.row.publishStatus == 0
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("videoManager.publish"),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row-center list-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.publish(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-xingzhuangjiehe",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("videoManager.publish")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          scope.row.taskType == 2
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("videoManager.aiPage"),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row-center list-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.aiPage(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-a-xialajiantoubeifen3",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("videoManager.aiPage")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          scope.row.status == "3"
                            ? _c(
                                "el-tooltip",
                                { attrs: { content: _vm.$t("file.download") } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row-center list-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(scope.row.url)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-a-dagoubeifen21",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("file.download")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-tooltip",
                            { attrs: { content: _vm.$t("remove") } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex-row-center list-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteVideo(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-delete",
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$t("remove")) + " "),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "jobFinishedTime",
                label: _vm.$t("time.generatedTime"),
                align: "center",
                width: "150",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          style: scope.row.hover ? "opacity: 0" : "opacity: 1",
                        },
                        [_vm._v(_vm._s(scope.row.jobFinishedTime))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "publishStatus",
                label: _vm.$t("videoManager.publishStatus"),
                align: "center",
                "min-width": "270",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "publish-status",
                          class: "publish-status-" + scope.row.publishStatus,
                          style: scope.row.hover ? "opacity: 0" : "opacity: 1",
                        },
                        [
                          scope.row.publishStatus == 0
                            ? _c("div", { staticClass: "flex-center" }, [
                                _c("i", {
                                  staticClass: "iconfont icon-dacha3",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("issueManager.unpublished")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          scope.row.publishStatus == 1
                            ? _c("div", { staticClass: "flex-center" }, [
                                _c("i", {
                                  staticClass: "iconfont icon-dacha1",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("issueManager.publishing")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          scope.row.publishStatus == 3
                            ? _c("div", { staticClass: "hidden flex-column" }, [
                                _c("div", { staticClass: "flex-center" }, [
                                  _c("i", {
                                    staticClass: "iconfont icon-a-dacha2",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("issueManager.failed")) +
                                      " "
                                  ),
                                ]),
                                scope.row.publishError
                                  ? _c(
                                      "span",
                                      { staticClass: "publish-status-des" },
                                      [_vm._v(_vm._s(scope.row.publishError))]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          scope.row.publishStatus == 2
                            ? _c("div", { staticClass: "flex-center" }, [
                                _c("i", {
                                  staticClass: "iconfont icon-a-dacha3",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("issueManager.published")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("videoManager.publishAccount"),
                align: "center",
                "min-width": "110px",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "flex-column",
                          style: scope.row.hover ? "opacity: 0" : "opacity: 1",
                        },
                        [
                          scope.row.publishList &&
                          scope.row.publishList.length > 0
                            ? _c(
                                "div",
                                _vm._l(
                                  scope.row.publishList,
                                  function (publishItem) {
                                    return _c(
                                      "div",
                                      {
                                        key: publishItem.id,
                                        staticClass: "flex-row-center",
                                      },
                                      [
                                        _c("el-avatar", {
                                          staticClass: "f-shrink",
                                          attrs: {
                                            size: 20,
                                            src: publishItem.userImage,
                                          },
                                        }),
                                        _c("span", { staticClass: "ml5" }, [
                                          _vm._v(
                                            _vm._s(publishItem.displayName)
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "publishTime",
                label: _vm.$t("videoManager.publishTime"),
                align: "center",
                width: "150",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          style: scope.row.hover ? "opacity: 0" : "opacity: 1",
                        },
                        [_vm._v(_vm._s(scope.row.publishTime))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            total: _vm.total,
            page: _vm.queryParams.pageNum,
            limit: _vm.queryParams.pageSize,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.queryParams, "pageNum", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.queryParams, "pageSize", $event)
            },
            pagination: _vm.getList,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }