var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "publicDialog tagRadio",
      attrs: {
        visible: _vm.localVisible,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.$t("profile.accountCollaboration"))),
          ]),
          _c("i", { staticClass: "el-icon-close", on: { click: _vm.close } }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "flexCenter" },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "flex-center",
              staticStyle: { "margin-left": "50px" },
              model: {
                value: _vm.profileType,
                callback: function ($$v) {
                  _vm.profileType = $$v
                },
                expression: "profileType",
              },
            },
            [
              _c("el-radio", { attrs: { label: "new" } }, [
                _vm._v(_vm._s(_vm.$t("profile.createCollaboration"))),
              ]),
              _c("el-radio", { attrs: { label: "choose" } }, [
                _vm._v(_vm._s(_vm.$t("profile.selectCollaborationID"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "accountHeight" },
        [
          _vm.profileType === "choose"
            ? _c(
                "div",
                { staticClass: "flexEnd" },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "320px", "margin-right": "5px" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "account-input",
                          attrs: {
                            placeholder: _vm.$t("profile.enterTip"),
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            },
                          },
                          model: {
                            value: _vm.queryParams.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "username", $$v)
                            },
                            expression: "queryParams.username",
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "el-input__icon iconfont icon-sousuotubiao",
                            attrs: { slot: "prefix" },
                            on: { click: _vm.handleQuery },
                            slot: "prefix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t("refresh"),
                        placement: "top",
                      },
                    },
                    [
                      _c("el-button", {
                        staticStyle: { width: "40px", height: "40px" },
                        attrs: {
                          size: "mini",
                          circle: "",
                          icon: "el-icon-refresh",
                        },
                        on: { click: _vm.init },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.profileType === "new"
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "profileForm",
                      staticClass: "newProfile",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-position": "top",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("account.username"),
                            prop: "topHeader",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "50",
                              placeholder: _vm.$t("profile.enterTipAllowNone"),
                            },
                            model: {
                              value: _vm.form.topHeader,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "topHeader", $$v)
                              },
                              expression: "form.topHeader",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("account.role"),
                            prop: "roleName",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "inputStyle",
                              staticStyle: { width: "100%" },
                              attrs: {
                                maxlength: "50",
                                placeholder: _vm.$t("account.tipRole"),
                              },
                              model: {
                                value: _vm.form.roleName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "roleName", $$v)
                                },
                                expression: "form.roleName",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "flex-center",
                                  attrs: { slot: "prepend" },
                                  slot: "prepend",
                                  model: {
                                    value: _vm.form.roleName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "roleName", $$v)
                                    },
                                    expression: "form.roleName",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: _vm.$t("account.sales") },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("account.sales")))]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        label: _vm.$t("account.operations"),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("account.operations"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        label: _vm.$t("account.marketing"),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("account.marketing"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("account.description"),
                            prop: "description",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "textarea",
                              maxlength: "200",
                              "show-word-limit": "",
                              rows: 3,
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.profileType === "choose"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.profileLoading,
                      expression: "profileLoading",
                    },
                  ],
                  ref: "singleTable",
                  attrs: {
                    data: _vm.profile,
                    "highlight-current-row": "",
                    "header-row-class-name": "custom_table_header",
                    height: "300",
                  },
                  on: { "current-change": _vm.handleCurrentChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: _vm.$t("account.number"),
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "topHeader",
                      label: _vm.$t("profile.topHeader"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.type === "new"
                                ? _c(
                                    "div",
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "50",
                                          clearable: "",
                                          placeholder: _vm.$t(
                                            "profile.enterTipAllowNone"
                                          ),
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                          },
                                          input: function ($event) {
                                            _vm.topHeader = scope.row.topHeader
                                          },
                                        },
                                        model: {
                                          value: scope.row.topHeader,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "topHeader",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.topHeader",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _vm._v(
                                      " " + _vm._s(scope.row.topHeader) + " "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2970181501
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "roleName", label: _vm.$t("account.role") },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "bind",
                      label: _vm.$t("profile.boundSocialMedia"),
                      width: "250",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "flex-row-center flex-wrap",
                                  staticStyle: { gap: "10px", padding: "5px" },
                                },
                                _vm._l(scope.row.bind, function (item) {
                                  return _c("i", {
                                    key: item,
                                    staticClass: "iconfont mr5 mb5 font18",
                                    class: ["icon-" + item, item],
                                  })
                                }),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      678978175
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "unbind",
                      label: _vm.$t("profile.availableSocialMedia"),
                      width: "250",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "flex-row-center f-wrap",
                                  staticStyle: { gap: "10px", padding: "5px" },
                                },
                                _vm._l(scope.row.unbind, function (item) {
                                  return _c("i", {
                                    key: item,
                                    staticClass:
                                      "iconfont mr5 mb5 font18 unbind",
                                    class: "icon-" + item,
                                  })
                                }),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2183458384
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("operate"), align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              (_vm.id && scope.row.id === _vm.id) ||
                              (!_vm.id && _vm.isNew && scope.row.type === "new")
                                ? _c("i", {
                                    staticClass: "iconfont icon-dagou",
                                    staticStyle: {
                                      color: "#0092FF",
                                      "font-size": "20px",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1301842119
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.profileType === "choose"
            ? _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 20,
                    expression: "total > 20",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getProfileList,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-center mt20" },
        [
          _c(
            "el-button",
            {
              staticClass: "cancelBtn btnWidth120",
              on: { click: _vm.handleCancel },
            },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _vm.profileType === "new"
            ? _c(
                "el-button",
                {
                  staticClass: "submitBtn btnWidth120",
                  attrs: { type: "primary" },
                  on: { click: _vm.create },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              )
            : _vm._e(),
          _vm.profileType === "choose"
            ? _c(
                "el-button",
                {
                  staticClass: "submitBtn btnWidth120",
                  attrs: {
                    type: "primary",
                    disabled: !_vm.id && !_vm.topHeader,
                  },
                  on: { click: _vm.choose },
                },
                [_vm._v(_vm._s(_vm.$t("profile.confirmSelection")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }