var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "voice-container" },
    [
      _vm.type === 0
        ? _c("div", [_vm._v(_vm._s(_vm.$t("editVideo.transitionSetup")))])
        : _vm._e(),
      _c(
        "el-radio-group",
        {
          staticClass: "custom-radio-group mt20",
          on: { input: _vm.selectItem },
          model: {
            value: _vm.item,
            callback: function ($$v) {
              _vm.item = $$v
            },
            expression: "item",
          },
        },
        [
          _c(
            "div",
            { staticClass: "voice-items" },
            _vm._l(_vm.transitionList, function (transition) {
              return _c(
                "el-radio",
                {
                  key: transition.id,
                  staticClass: "flex-row-center voice-item",
                  attrs: { label: transition.subtype },
                },
                [
                  _c("div", { staticClass: "flex-row-center" }, [
                    _c("img", {
                      staticClass: "transition-preview",
                      attrs: { src: transition.url, alt: "" },
                    }),
                    _vm._v(" " + _vm._s(transition.label) + " "),
                  ]),
                ]
              )
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }