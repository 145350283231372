<!-- 重复数据提示弹窗 -->
<template>
  <el-dialog
    class="publicDialog"
    :visible.sync="localVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleBeforeClose"
    :show-close="false"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ $t('systemPrompt') }}</div>
        <i
          class="el-icon-close"
          @click="close"
        ></i>
      </div>
    </div>
    <div class="font18 mb5">{{ $t('account.repeatTip') }}</div>
    <el-table
      header-row-class-name="custom_table_header"
      height="300"
      :data="repeatList"
      @current-change="handleCurrentChange"
    >
      <el-table-column
        prop="topHeader"
        width="120"
        :label="$t('account.user')"
      >
        <template slot-scope="scope">
          <div class="single-line-ellipsis">
            {{ scope.row.topHeader }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="platform"
        width="120"
        :label="$t('account.bindingSource')"
      >
        <template slot-scope="scope">
          <div
            class="flex-row-center single-line-ellipsis"
            style="padding: 5px"
          >
            <i
              class="iconfont mr5 font20"
              :class="['icon-' + scope.row.platform + '-normal', scope.row.platform]"
            ></i>
            <dict-tag
              :options="dict.type.social_platform"
              :value="scope.row.platform"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="username"
        width="160"
        :label="$t('account.bindHomePage')"
      >
        <template slot-scope="scope">
          <div class="flex-row-center">
            <el-avatar
              :src="scope.row.userImage"
              size="small"
              style="margin-right: 10px;flex-shrink: 0;"
            ></el-avatar>
            <div
              class="f-grow single-line-ellipsis"
              style="text-align: left;"
            >
              {{ scope.row.username }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="displayName"
        :label="$t('account.nickname')"
      >
        <template slot-scope="scope">
          {{ scope.row.displayName }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('account.bindTime')"
        prop="createTime "
      >
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime , '{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operate')"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-tooltip :content="$t('account.unbundle')">
            <el-button
              v-loading="deleteLoading && scope.row.id == id"
              icon="iconfont icon-a-xialajiantoubeifen22"
              circle
              type="text"
              size="small"
              @click="handleUnbind(scope.row)"
            >{{ $t('account.unbundle') }}</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="cancelBtn"
        @click="handleCancel"
      >{{$t('cancel')}}</el-button>
      <el-button
        class="submitBtn"
        type="primary"
        @click="handleConfirm"
      >{{$t('confirm')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { delAccount } from "../../../api/account/tiktok";

export default {
  name: "repeatDialog",
  dicts: ["social_platform"],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    repeatList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localVisible: this.visible,
      deleteLoading: false,
      id: undefined,
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.id = val.id;
    },
    // 解绑
    handleUnbind(row) {
      const ids = row.id;
      this.$modal
        .confirm(this.$t('account.tipUnbind'))
        .then(() => {
          this.deleteLoading = true;
          return delAccount(ids);
        })
        .then(() => {
          this.$emit("unbindSueecss");
          this.$modal.msgSuccess(this.$t("account.successfully"));
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    handleInput(value) {
      if (value != null) {
        var label = value[value.length - 1];
        if (label != null && label.length > 20) {
          value[value.length - 1] = label.substring(0, 20);
        }
      }
    },
    handleBeforeClose(done) {
      this.close();
      done();
    },
    handleCancel() {
      this.close();
    },
    handleConfirm() {
      this.$emit("confirm");
      this.close();
    },
    close() {
      this.localVisible = false;
      this.$emit("update:visible", false);
    },
  },
};
</script>
<style scoped>
.dialog-footer {
  text-align: right;
}
</style>
