var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "template-card",
      class: { selected: _vm.isSelected },
      on: { click: _vm.selectItem },
    },
    [
      _c("div", { staticClass: "flex-row-center video-top" }, [
        _c("div", { staticClass: "left-section" }, [
          _c("video", {
            staticClass: "video-cover",
            attrs: { src: _vm.item.url, alt: "video" },
          }),
          _vm.item.duration && _vm.item.duration > 0
            ? _c("span", { staticClass: "video-duration" }, [
                _vm._v(_vm._s(_vm.secondsFormatTime(_vm.item.duration))),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "f-grow ml16" },
          _vm._l(_vm.item.text, function (temp, index) {
            return _c(
              "div",
              { key: index, staticClass: "flex-row-center w-100 mb16" },
              [
                _c("div", { staticClass: "language-tag" }, [
                  _vm._v(_vm._s(_vm.getLanguageName(temp.value))),
                ]),
                _c("el-input", {
                  staticClass: "w-100",
                  attrs: {
                    type: "textarea",
                    maxlength: "200",
                    autosize: { minRows: 1, maxRows: 10 },
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.onInputBlur(temp)
                    },
                  },
                  model: {
                    value: temp.text,
                    callback: function ($$v) {
                      _vm.$set(temp, "text", $$v)
                    },
                    expression: "temp.text",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "btn-bottom flex-row-center f-between" }, [
        _c("span", { staticClass: "time-duration" }, [
          _vm._v(
            _vm._s(_vm.$t("editVideo.duration")) +
              "：" +
              _vm._s(_vm.secondsFormatTime(_vm.item.startTime)) +
              " - " +
              _vm._s(_vm.secondsFormatTime(_vm.item.endTime))
          ),
        ]),
        _c("div", { staticClass: "flex-row-center btn-group f-end" }, [
          _c(
            "div",
            {
              staticClass: "flex-row-center",
              on: {
                click: function ($event) {
                  return _vm.$emit("make-video", _vm.item)
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-orange" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("editVideo.autoGenerate")))]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "flex-row-center ml16",
              on: {
                click: function ($event) {
                  return _vm.$emit("replace-video", _vm.item)
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-connection" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("editVideo.replaceClip")))]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "flex-row-center ml16",
              on: {
                click: function ($event) {
                  return _vm.$emit("delete-video", _vm.item)
                },
              },
            },
            [
              _c("i", { staticClass: "iconfont icon-delete" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("remove")))]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }