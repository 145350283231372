<template>
  <div class="flex-column h-100">
    <div
      class="f-grow"
      style="overflow-y: auto;"
    >
      <el-descriptions
        v-loading="loading"
        label-class-name="my-label"
        :column="1"
        class="f-grow rounded-descriptions"
        border
      >
        <el-descriptions-item>
          <template slot="label">
            {{ $t('account.username') }}
          </template>
          <span v-if="isEdit">
            <el-input
              v-model="detail.topHeader"
              maxlength="50"
              :placeholder="$t('tip.input')"
            />
          </span>
          <span v-else>
            {{ detail.topHeader }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            {{ $t('account.role') }}
          </template>
          <span v-if="isEdit">
            <el-input
              v-model="detail.roleName"
              maxlength="50"
              :placeholder="$t('tip.input')"
            />
          </span>
          <span v-else>
            {{ detail.roleName }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            {{ $t('account.description') }}
          </template>
          <span v-if="isEdit">
            <el-input
              type="textarea"
              maxlength="200"
              show-word-limit
              :rows="3"
              v-model="detail.description"
            />
          </span>
          <span v-else>
            {{ detail.description }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            {{ $t('account.bindTime') }}
          </template>
          {{ detail.bindingTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            {{ $t('account.bindPlatform') }}
          </template>
          <div
            class="mt8 social"
            style="margin-bottom: 4px"
          >
            <div
              v-for="(item, index) in detail.socialList"
              :key="index"
              class="flex-row-center mb20"
            >
              <div class="w-50 flex-row-center">
                <i
                  class="iconfont mr5 font14"
                  :class="['icon-' + item.platform + '-normal', item.platform]"
                ></i>
                {{ item.platform }}
              </div>
              <div class="w-50 flex-row-center">
                <el-avatar
                  class="mr8 f-shrink"
                  :src="item.userImage"
                  :size="32"
                ></el-avatar>
                <div
                  class="f-grow"
                  style="text-align: left;"
                >
                  {{ item.platform == 'facebook'? item.pageName :item.username }}
                </div>
              </div>
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="f-shrik flex-center">
      <el-button
        class="cancelBtn"
        @click="$emit('cancel')"
      >{{ $t('cancel') }}</el-button>
      <el-button
        :loading="btLoading"
        class="submitBtn"
        @click="handleSure"
      >{{ $t('sure') }}</el-button>
    </div>
  </div>
</template>
<script>
import { editProfile, getProfile } from "../../api/account/profile";

export default {
  name: "Detail",
  props: {
    profileTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      id: "",
      isEdit: false, //是否可编辑
      loading: false,
      detail: {},
      btLoading: false,
    };
  },
  created() {
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    changeSelect() {
      this.$forceUpdate(); // 手动触发更新
    },
    init(id, isEdit) {
      this.id = id;
      this.isEdit = isEdit;
      this.getDetail();
    },
    getDetail() {
      this.loading = true;
      getProfile(this.id)
        .then((res) => {
          this.detail = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSure() {
      if (this.isEdit) {
        if (!this.detail.topHeader) {
          this.$modal.msgError(this.$t("profile.enterTip"));
          return;
        }
        this.btLoading = true;
        editProfile(this.detail)
          .then((res) => {
            this.$emit("update");
          })
          .finally(() => {
            this.btLoading = false;
          });
      } else {
        this.$emit("cancel");
      }
    },
  },
};
</script>
<style scoped>
.my-label {
  width: 140px;
}
.social {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
/* 自定义 el-descriptions 圆角样式 */
.rounded-descriptions {
  background: transparent;
  border-radius: 12px;
  overflow: hidden;
}
::v-deep .el-descriptions-item__label {
  background: #f4f4f5;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 40px !important;
  text-align: left;
  font-style: normal;
}
::v-deep .el-descriptions--medium.is-bordered .el-descriptions-item__cell {
  padding: 0 0 0 20px;
}
</style>
