var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aicross-container" }, [
    _c("div", { staticClass: "aicross-title flex-row-center f-between mb16" }, [
      _c(
        "div",
        { staticClass: "flex-row-center" },
        [
          _vm._v(_vm._s(_vm.$t("workbench.videoByText")) + " "),
          _c(
            "el-popover",
            {
              attrs: {
                placement: "left-start",
                width: "518",
                trigger: "click",
              },
            },
            [
              _c("div", { staticClass: "popover-title" }, [
                _vm._v(_vm._s(_vm.$t("guidance.txtToVidWelcome"))),
              ]),
              _c("div", { staticClass: "popover-content" }, [
                _vm._v(_vm._s(_vm.$t("guidance.txtToVidInstructions")) + " "),
              ]),
              _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.videoSrc },
              }),
              _c(
                "div",
                { attrs: { slot: "reference" }, slot: "reference" },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { content: _vm.$t("guidance.guide") } },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-zhushi ml8 pointer",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex-row-center pointer back-list",
          on: { click: _vm.goBack },
        },
        [
          _c("i", { staticClass: "iconfont icon-fanhui mr8 " }),
          _vm._v(" " + _vm._s(_vm.$t("backList")) + " "),
        ]
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTab == 0,
            expression: "currentTab == 0",
          },
        ],
      },
      [
        _c("router-view"),
        _c(
          "div",
          [
            _c("div", { staticClass: "aicross-content" }, [
              _c("div", { staticClass: "aicross-content-left" }, [
                _c("div", { staticClass: "cut-scroll" }, [
                  _c("div", [
                    _c("div", { staticClass: "flex-row-center f-between" }, [
                      _c(
                        "span",
                        { staticClass: "headline-title flex-row-center" },
                        [
                          _c("img", {
                            staticClass: "img-title",
                            attrs: {
                              src: require("../../assets/images/aiCutting/copywriting.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("aiCrosscutting.scriptCopy")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "reset-btn pointer",
                          on: { click: _vm.reset },
                        },
                        [
                          _c("i", { staticClass: "iconfont icon-shuaxin" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("aiCrosscutting.resetContent")) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _vm.activeTab === "template"
                      ? _c(
                          "div",
                          { staticClass: "mt20" },
                          [
                            _vm.currentTemplate && _vm.currentTemplate.title
                              ? _c("TemplateItemEdit", {
                                  attrs: { template: _vm.currentTemplate },
                                  on: {
                                    "change-Template": _vm.changeTemplate,
                                    "update-template": _vm.updateTemplate,
                                  },
                                })
                              : _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.templateLoading,
                                        expression: "templateLoading",
                                      },
                                    ],
                                    staticClass: "template-selector",
                                  },
                                  [
                                    _vm._l(
                                      _vm.templates.slice(0, 4),
                                      function (template) {
                                        return _c(
                                          "div",
                                          {
                                            key: template.id,
                                            staticClass: "template-card-item",
                                          },
                                          [
                                            _c("TemplateItem", {
                                              attrs: { template: template },
                                              on: {
                                                "view-template":
                                                  _vm.viewTemplate,
                                                "apply-template":
                                                  _vm.applyTemplate,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "flex-center w-100" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                            on: { click: _vm.moreTemplate },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "aiCrosscutting.viewMore"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("div", { staticClass: "cut-title" }, [
                              _vm._v(
                                _vm._s(_vm.$t("aiCrosscutting.inputPrompt")) +
                                  "："
                              ),
                            ]),
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                maxlength: "200",
                                "show-word-limit": "",
                                rows: 8,
                                placeholder: _vm.$t(
                                  "aiCrosscutting.keywordPrompt"
                                ),
                              },
                              model: {
                                value: _vm.form.text,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "text", $$v)
                                },
                                expression: "form.text",
                              },
                            }),
                          ],
                          1
                        ),
                    _c(
                      "div",
                      { staticClass: "flex-row-center f-end mt8 mr8" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "button-normal flex-row-center",
                            on: { click: _vm.moreTemplate },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-wenanmoban",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("aiCrosscutting.selectTemp")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "button-normal flex-row-center ml8",
                            on: { click: _vm.importText },
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-sucaiku1" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("aiCrosscutting.selectMaterial")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-column" },
                    [
                      _c("div", { staticClass: "cut-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("aiCrosscutting.contentLength")) + "："
                        ),
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.wordsSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wordsSize", $$v)
                            },
                            expression: "form.wordsSize",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 50 } }, [
                            _vm._v(
                              _vm._s(_vm.$t("aiCrosscutting.short")) + "(0～50)"
                            ),
                          ]),
                          _c("el-radio", { attrs: { label: 100 } }, [
                            _vm._v(
                              _vm._s(_vm.$t("aiCrosscutting.medium")) +
                                "(50～100)"
                            ),
                          ]),
                          _c("el-radio", { attrs: { label: 200 } }, [
                            _vm._v(
                              _vm._s(_vm.$t("aiCrosscutting.long")) +
                                "(100～200)"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "cut-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("aiCrosscutting.generationCount")) +
                            "："
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex-row-center" },
                        [
                          _c("el-input-number", {
                            staticClass: "number-input",
                            attrs: { min: 1, max: 5 },
                            model: {
                              value: _vm.form.generateCount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "generateCount", $$v)
                              },
                              expression: "form.generateCount",
                            },
                          }),
                          _c("span", { staticClass: "num-tip ml8" }, [
                            _c("i", { staticClass: "iconfont icon-zhushi" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("aiCrosscutting.maxGeneration")) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "cut-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("aiCrosscutting.seoIntegration")) + "："
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticClass: "label-select",
                          attrs: {
                            filterable: "",
                            multiple: "",
                            clearable: "",
                            "default-first-option": "",
                            placeholder: _vm.$t("tip.select"),
                          },
                          model: {
                            value: _vm.form.seo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "seo", $$v)
                            },
                            expression: "form.seo",
                          },
                        },
                        _vm._l(_vm.seoList, function (dict) {
                          return _c("el-option", {
                            key: dict.id,
                            attrs: { label: dict.label, value: dict.label },
                          })
                        }),
                        1
                      ),
                      _c("div", { staticClass: "cut-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("aiCrosscutting.golden3sStart")) + "："
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "golden-3s" },
                        _vm._l(_vm.goldenTypes, function (item) {
                          return _c(
                            "div",
                            {
                              key: item,
                              staticClass: "golden-3s-item",
                              class: { active: _vm.form.startStyle === item },
                              on: {
                                click: function ($event) {
                                  _vm.form.startStyle = item
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-center mt10" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "submitBtn",
                        attrs: { loading: _vm.contentLoading },
                        on: { click: _vm.generate },
                      },
                      [_vm._v(_vm._s(_vm.$t("aiCrosscutting.generateContent")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "aicross-content-right" }, [
                _c("div", { staticClass: "right-section" }, [
                  _c("div", { staticClass: "cut-scroll" }, [
                    _c("div", { staticClass: "flex-row-center f-wrap" }, [
                      _c(
                        "span",
                        { staticClass: "headline-title flex-row-center" },
                        [
                          _c("img", {
                            staticClass: "img-title",
                            attrs: {
                              src: require("../../assets/images/aiCutting/preview.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("aiCrosscutting.scriptPreview")) +
                              " "
                          ),
                        ]
                      ),
                      _c("span", { staticClass: "content-tip" }, [
                        _c("i", { staticClass: "iconfont icon-zhushi" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("aiCrosscutting.parseHint")) + " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.contentLoading,
                            expression: "contentLoading",
                          },
                        ],
                        staticClass: "preview-list",
                      },
                      [
                        _vm._l(_vm.generatedContents, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "preview-item pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.checkChange(item)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "item-num" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("aiCrosscutting.script")) +
                                    " " +
                                    _vm._s(index + 1) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "item-content" }, [
                                _c("div", { staticClass: "item-content-top" }, [
                                  item && item.title
                                    ? _c("div", { staticClass: "item-title" }, [
                                        _vm._v(_vm._s(item.title)),
                                      ])
                                    : _vm._e(),
                                  _c("div", [_vm._v(_vm._s(item.text))]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-content-bottom flex-row-center f-between",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex-row-center f-wrap" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value:
                                                  item.loading &&
                                                  _vm.rewriteType === "bt",
                                                expression:
                                                  "item.loading && rewriteType === 'bt'",
                                              },
                                            ],
                                            staticClass:
                                              "flex-row-center bt-item",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.rewrite(
                                                  "bt",
                                                  index,
                                                  item.text
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-wenzi",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "aiCrosscutting.titleExtraction"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value:
                                                  item.loading &&
                                                  _vm.rewriteType === "kx",
                                                expression:
                                                  "item.loading && rewriteType === 'kx'",
                                              },
                                            ],
                                            staticClass:
                                              "flex-row-center bt-item",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.rewrite(
                                                  "kx",
                                                  index,
                                                  item.text
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-wenanbuchong",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "aiCrosscutting.scriptSupplement"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value:
                                                  item.loading &&
                                                  _vm.rewriteType === "rs",
                                                expression:
                                                  "item.loading && rewriteType === 'rs'",
                                              },
                                            ],
                                            staticClass:
                                              "flex-row-center bt-item",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.rewrite(
                                                  "rs",
                                                  index,
                                                  item.text
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-wenanrunse",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "aiCrosscutting.scriptPolish"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value:
                                                  item.loading &&
                                                  _vm.rewriteType === "sx",
                                                expression:
                                                  "item.loading && rewriteType === 'sx'",
                                              },
                                            ],
                                            staticClass:
                                              "flex-row-center bt-item",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.rewrite(
                                                  "sx",
                                                  index,
                                                  item.text
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-a-zhutibeifen3",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "aiCrosscutting.scriptSimplify"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "item-choose flex-row-center",
                                        class: { checked: item.checked },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconfont icon-dagou",
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("select")) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        _vm.generatedContents.length === 0
                          ? _c(
                              "div",
                              { staticClass: "flex-center empty-content" },
                              [
                                _c(
                                  "el-empty",
                                  {
                                    attrs: {
                                      "image-size": 128,
                                      image: require("@/assets/images/aiCutting/blank.png"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "description" },
                                        slot: "description",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "empty-title" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "aiCrosscutting.keywordInputHint"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "empty-desc" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "aiCrosscutting.uniqueContent"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.generatedContents.length > 0,
                          expression: "generatedContents.length > 0",
                        },
                      ],
                      staticClass: "flex-center mt10",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "submitBtn",
                          attrs: { loading: _vm.parseLoading },
                          on: { click: _vm.parseAll },
                        },
                        [_vm._v(_vm._s(_vm.$t("aiCrosscutting.analyze")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("templateItemDetail", {
              attrs: {
                visible: _vm.detailVisible,
                template: _vm.templateDetail,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.detailVisible = $event
                },
                confirm: _vm.applyTemplate,
              },
            }),
            _c("TemplateMore", {
              attrs: { visible: _vm.moreVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.moreVisible = $event
                },
                "view-template": _vm.viewTemplate,
                "apply-template": _vm.applyTemplate,
              },
            }),
            _c("material-drawer", {
              ref: "materialDrawer",
              attrs: { visible: _vm.drawerMaterial },
              on: {
                "update:visible": function ($event) {
                  _vm.drawerMaterial = $event
                },
                selectionConfirmed: _vm.selectionText,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTab == 1,
            expression: "currentTab == 1",
          },
        ],
      },
      [
        _c("EditManuscripts", {
          ref: "editManuscripts",
          on: {
            last: function ($event) {
              return _vm.last(0)
            },
            next: _vm.next,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTab == 2,
            expression: "currentTab == 2",
          },
        ],
      },
      [
        _c("EditVideo", {
          ref: "editVideo",
          on: {
            last: function ($event) {
              return _vm.last(1)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }