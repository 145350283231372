var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-collapse",
        [
          _c(
            "el-collapse-item",
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { width: "60px" } }, [
                  _vm._v("Logo"),
                ]),
                _c("div", { staticClass: "image-thumbnail-multiple" }, [
                  _vm.settings.logoTrackClipsBo &&
                  _vm.settings.logoTrackClipsBo.mediaUrl
                    ? _c("img", {
                        staticClass: "image-thumbnail",
                        attrs: { src: _vm.settings.logoTrackClipsBo.mediaUrl },
                      })
                    : _vm._e(),
                ]),
              ]),
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.settings,
                    "label-position": "top",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticStyle: { width: "100%" }, attrs: { label: "" } },
                    [
                      _vm.settings.logoTrackClipsBo &&
                      _vm.settings.logoTrackClipsBo.mediaUrl
                        ? _c(
                            "div",
                            {
                              staticClass: "material-container",
                              on: {
                                mouseover: function ($event) {
                                  _vm.settings.logoTrackClipsBo.hover = true
                                },
                                mouseleave: function ($event) {
                                  _vm.settings.logoTrackClipsBo.hover = false
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "material-thumbnail",
                                attrs: {
                                  src: _vm.settings.logoTrackClipsBo.mediaUrl,
                                  alt: "material",
                                },
                              }),
                              _vm.settings.logoTrackClipsBo.hover
                                ? _c("div", { staticClass: "overlay" }, [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeMaterial(3)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("basicInfo.remove"))
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _c("picUpload", {
                            on: {
                              picSelectMaterial: _vm.picSelectMaterial,
                              showLocaPic: _vm.showLocaPic,
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("mixing.position") } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.logoChange },
                          model: {
                            value: _vm.settings.logoTrackClipsBo.position,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.settings.logoTrackClipsBo,
                                "position",
                                $$v
                              )
                            },
                            expression: "settings.logoTrackClipsBo.position",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "left-top" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.leftTop")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "center-top" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.centerTop")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "right-top" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.rightTop")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "left-bottom" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.leftBottom")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "center-bottom" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.centerBottom")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "right-bottom" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.rightBottom")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { width: "60px" } }, [
                  _vm._v(_vm._s(_vm.$t("mixing.cover"))),
                ]),
                _c("div", { staticClass: "image-thumbnail-multiple" }, [
                  _vm.settings.coverUrl &&
                  _vm.getTypeByUrl(_vm.settings.coverUrl) === "image"
                    ? _c("img", {
                        staticClass: "image-thumbnail",
                        attrs: { src: _vm.settings.coverUrl },
                      })
                    : _vm._e(),
                  _vm.settings.coverUrl &&
                  _vm.getTypeByUrl(_vm.settings.coverUrl) === "video"
                    ? _c("video", {
                        staticClass: "image-thumbnail",
                        attrs: { src: _vm.settings.coverUrl },
                      })
                    : _vm._e(),
                ]),
              ]),
              !_vm.settings.coverUrl
                ? _c("picUpload", {
                    on: {
                      picSelectMaterial: function ($event) {
                        return _vm.chooseMaterial(4)
                      },
                      showLocaPic: _vm.showLocaPic4,
                    },
                  })
                : _c(
                    "div",
                    {
                      staticClass: "material-container",
                      on: {
                        mouseover: function ($event) {
                          _vm.settings.coverUrlHover = true
                        },
                        mouseleave: function ($event) {
                          _vm.settings.coverUrlHover = false
                        },
                      },
                    },
                    [
                      _vm.getTypeByUrl(_vm.settings.coverUrl) === "image"
                        ? _c("img", {
                            staticClass: "material-thumbnail",
                            attrs: {
                              src: _vm.settings.coverUrl,
                              alt: "material",
                            },
                          })
                        : _c("video", {
                            staticClass: "material-thumbnail",
                            attrs: { src: _vm.settings.coverUrl },
                          }),
                      _vm.settings.coverUrlHover
                        ? _c("div", { staticClass: "overlay" }, [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeMaterial(4)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicInfo.remove")))]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { width: "60px" } }, [
                  _vm._v(_vm._s(_vm.$t("mixing.backCover"))),
                ]),
                _c("div", { staticClass: "image-thumbnail-multiple" }, [
                  _vm.settings.backCoverUrl &&
                  _vm.getTypeByUrl(_vm.settings.backCoverUrl) === "image"
                    ? _c("img", {
                        staticClass: "image-thumbnail",
                        attrs: { src: _vm.settings.backCoverUrl },
                      })
                    : _vm._e(),
                  _vm.settings.backCoverUrl &&
                  _vm.getTypeByUrl(_vm.settings.backCoverUrl) === "video"
                    ? _c("video", {
                        staticClass: "image-thumbnail",
                        attrs: { src: _vm.settings.backCoverUrl },
                      })
                    : _vm._e(),
                ]),
              ]),
              !_vm.settings.backCoverUrl
                ? _c("picUpload", {
                    on: {
                      picSelectMaterial: function ($event) {
                        return _vm.chooseMaterial(5)
                      },
                      showLocaPic: _vm.showLocaPic5,
                    },
                  })
                : _c(
                    "div",
                    {
                      staticClass: "material-container",
                      on: {
                        mouseover: function ($event) {
                          _vm.settings.backCoverUrlHover = true
                        },
                        mouseleave: function ($event) {
                          _vm.settings.backCoverUrlHover = false
                        },
                      },
                    },
                    [
                      _vm.getTypeByUrl(_vm.settings.backCoverUrl) === "image"
                        ? _c("img", {
                            staticClass: "material-thumbnail",
                            attrs: {
                              src: _vm.settings.backCoverUrl,
                              alt: "material",
                            },
                          })
                        : _c("video", {
                            staticClass: "material-thumbnail",
                            attrs: { src: _vm.settings.backCoverUrl },
                          }),
                      _vm.settings.backCoverUrlHover
                        ? _c("div", { staticClass: "overlay" }, [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeMaterial(5)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicInfo.remove")))]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
            ],
            2
          ),
        ],
        1
      ),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawer },
        on: {
          "update:visible": function ($event) {
            _vm.drawer = $event
          },
          selectionConfirmed: _vm.selectionConfirmed,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }